import { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SectionEditorDialog } from './SectionEditorDialog';
import { GuidelineSection, MenuItem } from '../../model/GuidelinePage';
import { SubMenusDisplayAndEditor } from './SubMenusDisplayAndEditor';
import { SectionContent } from '../guideline_display/SectionContent';
import { SectionHeading } from '../guideline_display/SectionHeading';

export type SectionDisplayAndEditorProps = {
  section: GuidelineSection;
  updateSection: (updatedSection: GuidelineSection) => void;
  deleteSection: (sectionId: string) => void;
  updateMenuItems: (sectionId: string, menuItems: MenuItem[]) => void;
};

export function SectionDisplayAndEditor({
  section,
  updateSection: setSection,
  deleteSection,
  updateMenuItems,
}: SectionDisplayAndEditorProps): JSX.Element {
  const [isContentEditorDialogOpen, setIsContentEditorDialogOpen] =
    useState(false);

  const handleClickEdit = () => {
    setIsContentEditorDialogOpen(true);
  };
  const handleClose = () => setIsContentEditorDialogOpen(false);

  const handleClickDelete = useCallback(() => {
    if (section.id) deleteSection(section.id);
  }, [deleteSection, section.id]);

  const handleMenuItemsChange = useCallback(
    (menuItems: MenuItem[]) => {
      if (section.id) updateMenuItems(section.id, menuItems);
    },
    [section.id, updateMenuItems]
  );

  const isMenusOnly = !(section.header || section.content || section.footer);

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardActionArea onClick={handleClickEdit}>
          <CardContent>
            {!isMenusOnly && (
              <>
                <SectionHeading heading={section.header} />
                <SectionContent content={section.content} />
                {!!section.footer && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {section.footer}
                  </Typography>
                )}
              </>
            )}
            {section.menuItems && (
              <SubMenusDisplayAndEditor
                menuItems={section.menuItems}
                setMenuItems={handleMenuItemsChange}
              />
            )}
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ backgroundColor: 'whitesmoke' }}>
          {section.id && (
            <>
              <Button size="small" onClick={handleClickEdit}>
                Edit
              </Button>
              <Box flexGrow={1} />
              <IconButton size="small" onClick={handleClickDelete}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </CardActions>
      </Card>
      <SectionEditorDialog
        open={isContentEditorDialogOpen}
        section={section}
        handleClose={handleClose}
        updateSection={setSection}
      />
    </>
  );
}
