import { Typography } from '@mui/material';

interface SaveStatusProps {
  isSaving: boolean;
}
export function SaveStatus({ isSaving }: SaveStatusProps) {
  const savingText = isSaving ? 'Saving...' : '';

  return <Typography>{savingText}</Typography>;
}
