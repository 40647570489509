import {
  OutlinedTextFieldProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useCallback, useRef, useEffect, useState } from 'react';
import { InsertImageDialog } from '../authoring/InsertImageDialog';
import { MarkdownFormattingBar } from './MarkdownFormattingBar';
import { SpecialCharactersMenu } from './SpecialCharactersMenu';
import { TitleEditorDialog } from './TitleEditorDialog';

interface SelectionRange {
  start: number;
  end: number;
}

interface MarkdownTextFieldProps extends OutlinedTextFieldProps {
  onUndoPress: () => void;
  onRedoPress: () => void;
  undoEnabled: boolean;
  redoEnabled: boolean;
}

export function MarkdownTextField(props: MarkdownTextFieldProps): JSX.Element {
  const {
    onUndoPress,
    onRedoPress,
    undoEnabled,
    redoEnabled,
    ...textfieldProps
  } = props;
  const selectionToApply = useRef<SelectionRange | undefined>();
  const [isSubPageDialogOpen, setIsSubPageDialogOpen] = useState(false);
  const textArea = useRef<HTMLTextAreaElement>();
  const [specialCharactersMenuAnchorEl, setSpecialCharactersMenuAnchorEl] =
    useState<HTMLElement>();
  const [isInsertImageDialogOpen, setIsInsertImageDialogOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsSubPageDialogOpen(false);
    setIsInsertImageDialogOpen(false);
    textArea.current?.focus();
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        !event.shiftKey &&
        event.key.toLowerCase() === 'z'
      ) {
        onUndoPress();
        return;
      }
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key.toLowerCase() === 'z'
      ) {
        onRedoPress();
        return;
      }
      if (textArea.current) {
        const newSelectionRange = applyModiferKey(event, textArea.current);
        selectionToApply.current = newSelectionRange;
      }
    },
    [onRedoPress, onUndoPress]
  );

  const handleModifier = useCallback((key: string) => {
    if (textArea.current) {
      const newSelectionRange = applyModifierToSelection(key, textArea.current);
      selectionToApply.current = newSelectionRange;
    }
  }, []);

  const handleImageClick = useCallback(() => {
    setIsInsertImageDialogOpen(true);
  }, []);

  const handleInsertImage = useCallback((imagePath) => {
    const textToInsert = `<img src='${imagePath}' style='width:100%' />\n`;
    if (textArea.current) {
      const newSelectionRange = insertText(textArea.current, textToInsert);
      selectionToApply.current = newSelectionRange;
    }
  }, []);

  const handleSubPageClick = useCallback(() => {
    setIsSubPageDialogOpen(true);
  }, []);

  const handleSpecialCharacter = useCallback((character) => {
    if (textArea.current) {
      const newSelectionRange = insertText(
        textArea.current,
        character,
        character.length
      );
      selectionToApply.current = newSelectionRange;
    }
    setSpecialCharactersMenuAnchorEl(undefined);
  }, []);

  useEffect(() => {
    if (selectionToApply.current && textArea.current) {
      const { start, end } = selectionToApply.current;
      textArea.current.setSelectionRange(start, end);
      selectionToApply.current = undefined;
      textArea.current.focus();
    }
  });

  const updatedProps: TextFieldProps = {
    ...textfieldProps,
    sx: { ...props.sx, marginTop: '0' },
    onKeyDown: handleKeyDown,
    inputRef: textArea,
    autoFocus: true,
  };

  const applySubPage = useCallback((newTitle: string, newSubtitle: string) => {
    if (textArea.current) {
      const haveSubtitle = newSubtitle.trim().length > 0;
      const subtitleText = haveSubtitle ? ` [${newSubtitle}]` : '';
      const before = `>> ${newTitle}${subtitleText}\n\n`;
      const after = `\n<<\n`;
      const newSelectionRange = wrapTextAroundSelection(
        textArea.current,
        before,
        after
      );
      selectionToApply.current = newSelectionRange;
    }
  }, []);

  return (
    <>
      <TitleEditorDialog
        open={isSubPageDialogOpen}
        title=""
        subtitle=""
        handleClose={handleClose}
        updateTitleAndSubtitle={applySubPage}
      />
      <SpecialCharactersMenu
        anchorEl={specialCharactersMenuAnchorEl}
        handleClose={() => setSpecialCharactersMenuAnchorEl(undefined)}
        handleCharacter={(character) => {
          handleSpecialCharacter(character);
        }}
      />
      <InsertImageDialog
        open={isInsertImageDialogOpen}
        handleClose={handleClose}
        insertImage={handleInsertImage}
      />
      <MarkdownFormattingBar
        boldHandler={() => handleModifier('b')}
        italicHandler={() => handleModifier('i')}
        linkHandler={() => handleModifier('k')}
        imageHandler={handleImageClick}
        subPageHandler={handleSubPageClick}
        undoHandler={onUndoPress}
        undoEnabled={undoEnabled}
        redoHandler={onRedoPress}
        redoEnabled={redoEnabled}
        specialCharactersHandler={(
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          setSpecialCharactersMenuAnchorEl(event.currentTarget);
        }}
      />
      <TextField {...updatedProps}>{props.children}</TextField>
    </>
  );
}

export function getMarkdownTextModiferForCmdKey(key: string): {
  before: string;
  after: string;
} {
  const lowerKey = key.toLowerCase();

  // Bold
  if (lowerKey === 'b') {
    return { before: '**', after: '**' };
  }

  // Italic
  if (lowerKey === 'i') {
    return { before: '_', after: '_' };
  }

  // Strikethrough
  if (lowerKey === 's') {
    return { before: '~~', after: '~~' };
  }

  // Link
  if (lowerKey === 'k') {
    return { before: '[', after: '](https://www.example.com)' };
  }

  // Sub-page
  if (lowerKey === '.') {
    return { before: '>> Title [optional subtitle]\n', after: '\n<<' };
  }

  return { before: '', after: '' };
}

export function applyModiferKey(
  event: React.KeyboardEvent<HTMLInputElement>,
  textArea: HTMLTextAreaElement
): SelectionRange | undefined {
  if (event.ctrlKey || event.metaKey) {
    if (event.key.toLowerCase() === 's') {
      // Don't trigger the browser's save behaviour
      event.preventDefault();
      // We can't do this on every key press as it stops the user being able to type changes
    }
    return applyModifierToSelection(event.key, textArea);
  }
  return undefined;
}

function applyModifierToSelection(
  key: string,
  textArea: HTMLTextAreaElement
): SelectionRange | undefined {
  const { before, after } = getMarkdownTextModiferForCmdKey(key);
  return wrapTextAroundSelection(textArea, before, after);
}

function wrapTextAroundSelection(
  textArea: HTMLTextAreaElement,
  before: string,
  after: string
): SelectionRange | undefined {
  const somethingToDo = before.length > 0 || after.length > 0;
  if (somethingToDo) {
    const currentText = textArea.value;
    const { selectionStart, selectionEnd } = textArea;
    // const beforeText = currentText.substring(0, selectionStart);
    const selectedText = currentText.substring(selectionStart, selectionEnd);
    // const afterText = currentText.substring(selectionEnd);
    const additionText = `${before}${selectedText}${after}`;
    const removalText = selectedText.substring(
      before.length,
      selectedText.length - after.length
    );
    const isRemoving =
      selectedText.startsWith(before) && selectedText.endsWith(after);
    const replacementText = isRemoving ? removalText : additionText;

    return insertText(textArea, replacementText, before.length);
  }
  return undefined;
}

function insertText(
  textArea: HTMLTextAreaElement,
  textToInsert: string,
  cursorMovement = 0
): SelectionRange | undefined {
  const previousSelectedText = textArea.value.substring(
    textArea.selectionStart,
    textArea.selectionEnd
  );

  // Make the change to find how this affects the selection range
  textArea.setRangeText(textToInsert);
  let newSelectionStart = textArea.selectionStart;
  let newSelectionEnd = textArea.selectionEnd;

  // If no selection then move the cursor
  if (newSelectionStart === newSelectionEnd) {
    newSelectionStart += cursorMovement;
    newSelectionEnd += cursorMovement;
  }
  // Grab the text as we want it to be
  const nextText = textArea.value;

  // Undo the change so the change event won't get swallowed
  textArea.setRangeText(previousSelectedText);

  const inputElementValueDescriptor = Object.getOwnPropertyDescriptor(
    window.HTMLTextAreaElement.prototype,
    'value'
  );
  if (inputElementValueDescriptor && inputElementValueDescriptor.set) {
    inputElementValueDescriptor.set.call(textArea, nextText);
    textArea.dispatchEvent(new Event('change', { bubbles: true }));
    return {
      start: newSelectionStart,
      end: newSelectionEnd,
    };
  }
  return undefined;
}
