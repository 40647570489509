import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

interface ListItemLinkProps {
  icon: React.ReactElement;
  primary: string;
  to: string;
  // eslint-disable-next-line react/require-default-props
  openInNewTab?: boolean;
}

export function ListItemLink({
  icon,
  primary,
  to,
  openInNewTab = false,
}: ListItemLinkProps): JSX.Element {
  const targetProp = openInNewTab ? '_blank' : '';
  const relProp = openInNewTab ? 'noopener noreferrer' : '';

  const renderLink = getRouterLink(to, targetProp, relProp);

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function getRouterLink(to: string, targetProp: string, relProp: string) {
  return forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
    (itemProps, ref) => (
      <RouterLink
        to={to}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...itemProps}
        role={undefined}
        target={targetProp}
        rel={relProp}
      />
    )
  );
}
