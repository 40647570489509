import { Box, Button, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import {
  OperationState,
  UploadAndConversionState,
} from '../../services/AwsWordConversion';

export type WordImportConversionProgressProps = {
  fileToUpload?: File;
  guidelineName?: string;
  isUploadAndConvertButtonDisabled: boolean;
  handleUploadAndConvertClick: () => void;
  uploadAndConvertState: UploadAndConversionState;
};

export function WordImportConversionProgress({
  fileToUpload,
  guidelineName,
  isUploadAndConvertButtonDisabled,
  handleUploadAndConvertClick,
  uploadAndConvertState,
}: WordImportConversionProgressProps): JSX.Element {
  return (
    <>
      {buildReadyToConvertProgress(fileToUpload, guidelineName)}
      <Button
        variant="contained"
        sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}
        disabled={isUploadAndConvertButtonDisabled}
        onClick={handleUploadAndConvertClick}
      >
        Upload and Convert
      </Button>
      {buildConversionProgress(uploadAndConvertState)}
    </>
  );
}

function buildReadyToConvertProgress(
  fileToUpload?: File,
  guidelineName?: string
): JSX.Element {
  const isMissingFileToUpload = fileToUpload === undefined;
  const noGuidelineName = guidelineName === undefined;
  const emptyGuidelineName = noGuidelineName
    ? true
    : guidelineName.trim().length === 0;
  const isMissingGuidelineName = noGuidelineName || emptyGuidelineName;

  const fileStatusIcon = isMissingFileToUpload ? (
    <ErrorIcon color="warning" />
  ) : (
    <CheckCircleIcon color="success" />
  );

  const nameStatusIcon = isMissingGuidelineName ? (
    <ErrorIcon color="warning" />
  ) : (
    <CheckCircleIcon color="success" />
  );

  return (
    <Box sx={{ color: 'darkslategrey' }}>
      <Grid container direction="row" alignItems="flex-start" spacing={1}>
        <Grid item>{fileStatusIcon}</Grid>
        <Grid item>
          <Typography>File to convert</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="flex-start" spacing={1}>
        <Grid item>{nameStatusIcon}</Grid>
        <Grid item>
          <Typography>Display name</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function buildConversionProgress(
  conversionState: UploadAndConversionState
): JSX.Element {
  return (
    <Box sx={{ color: 'darkslategrey' }}>
      <Grid container direction="row" alignItems="flex-start" spacing={1}>
        <Grid item>
          <UploadFileIcon
            color={getOperationStateIconColor(conversionState.upload)}
          />
        </Grid>
        <Grid item>
          <Typography>Upload: </Typography>
        </Grid>
        <Grid item>
          {operationStateToElement(
            conversionState.upload,
            conversionState.error
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="flex-start" spacing={1}>
        <Grid item>
          <AutoModeIcon
            color={getOperationStateIconColor(conversionState.conversion)}
          />
        </Grid>
        <Grid item>
          <Typography>Conversion:</Typography>
        </Grid>
        <Grid item>
          {operationStateToElement(
            conversionState.conversion,
            conversionState.error
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function operationStateToElement(
  operationState: OperationState,
  error?: Error
): JSX.Element {
  switch (operationState) {
    case OperationState.NotStarted:
      return <Typography>Not Started</Typography>;
    case OperationState.InProgress:
      return <Typography>In Progress...</Typography>;
    case OperationState.CompletedSuccessfully:
      return <CheckCircleIcon color="success" />;
    case OperationState.Error:
      return <Typography>`Error: ${error?.message}`</Typography>;
    default:
      return <div />;
  }
}

// function operationStateToString(
//   operationState: OperationState,
//   error?: Error
// ): string {
//   switch (operationState) {
//     case OperationState.NotStarted:
//       return 'Not Started';
//     case OperationState.InProgress:
//       return 'In Progress';
//     case OperationState.CompletedSuccessfully:
//       return 'Completed';
//     case OperationState.Error:
//       return `Error:${error?.message}`;
//     default:
//       return '';
//   }
// }

function getOperationStateIconColor(
  operationState: OperationState
):
  | 'disabled'
  | 'action'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined {
  switch (operationState) {
    case OperationState.NotStarted:
      return 'disabled';
    case OperationState.InProgress:
      return 'info';
    case OperationState.CompletedSuccessfully:
      return 'success';
    case OperationState.Error:
      return 'error';
    default:
      return undefined;
  }
}
