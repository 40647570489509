import { Dialog, Typography } from '@mui/material';
import { DropzoneRootProps } from 'react-dropzone';

const centreContentsStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

export type DropZoneFullScreenOverlayProps = {
  dropZoneProps: DropzoneRootProps;
  isDragActive: boolean;
};

export function DropZoneFullScreenOverlay({
  dropZoneProps,
  isDragActive,
}: DropZoneFullScreenOverlayProps): JSX.Element {
  return (
    <Dialog fullScreen open={isDragActive}>
      <div
        {...dropZoneProps}
        style={{
          margin: '20px',
          width: '96.5%',
          height: '100%',
          border: '5px dashed lightgrey',
        }}
      >
        <div style={centreContentsStyle}>
          <Typography>Drop the Word file here</Typography>
        </div>
      </div>
    </Dialog>
  );
}
