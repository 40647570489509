import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  Link,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from '../../model/GuidelinePage';
import { SectionContent } from './SectionContent';
// eslint-disable-next-line import/no-cycle
// import { Page } from './Page';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { SectionHeading } from './SectionHeading';

export type SectionParams = {
  section: GuidelineSection;
  allPages: Map<string, GuidelinePage>;
};

export function Section({ section, allPages }: SectionParams): JSX.Element {
  const isMenusOnly = !(section.header || section.content || section.footer);
  const { uriTransformer } = useGuidelinesProviderContext();

  return (
    <>
      {!isMenusOnly && (
        <CardContent key={section.id}>
          <SectionHeading heading={section.header} />
          <SectionContent content={section.content} />
          {!!section.footer && (
            <Typography variant="body2" color="textSecondary" component="p">
              {section.footer}
            </Typography>
          )}
        </CardContent>
      )}
      {section.menuItems && (
        <Box marginBottom="1rem" key={`${section.id}-menus`}>
          {section.menuItems.map((subMenuItem) =>
            buildMenuElement(subMenuItem, allPages, uriTransformer)
          )}
        </Box>
      )}
    </>
  );
}

function buildMenuElement(
  menuItem: MenuItem,
  allPages: Map<string, GuidelinePage>,
  uriTransformer: (uri: string) => string
) {
  switch (menuItem.targetType) {
    case 'page':
      return <MobilePageMenu menuItem={menuItem} key={menuItem.target} />;

    case 'pdf':
      return (
        <PdfMenu
          menuItem={menuItem}
          uriTransformer={uriTransformer}
          key={menuItem.target}
        />
      );

    case 'other':
      return buildDownloadMenu(menuItem, uriTransformer(menuItem.target));

    default:
      return buildPlaceholderMenu(
        menuItem.title,
        menuItem.subtitle,
        menuItem.targetType
      );
  }
}

function buildPlaceholderMenu(
  title: string,
  subtitle: string | undefined,
  targetType: string
) {
  const message = `Not implemented '${targetType}' menus.`;
  const id = `${title}=${subtitle}-${targetType}`;
  return (
    <Accordion disableGutters elevation={0} square key={id}>
      <AccordionSummary
        expandIcon={<NavigateNextIcon />}
        aria-controls="panel1a-content"
        id={`${id}-summary`}
      >
        <Stack>
          <Typography>{title}</Typography>
          <Typography variant="body2" sx={{ color: 'GrayText' }}>
            {subtitle}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails id={`${id}-details`}>
        <Typography>{message}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function buildDownloadMenu({ title, target }: MenuItem, url: string) {
  const id = `${title}-${target}`;
  return (
    <Link href={url} download={target} id={id} padding={2} key={id}>
      {title}
    </Link>
  );
}

// function buildPageMenu(
//   menuItem: MenuItem,
//   allPages: Map<string, GuidelinePage>
// ) {
//   return (
//     <Accordion disableGutters elevation={0} square>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Stack>
//           <Typography>{menuItem.title}</Typography>
//           <Typography variant="body2" sx={{ color: 'GrayText' }}>
//             {menuItem.subtitle}
//           </Typography>
//         </Stack>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Page
//           guideline={
//             allPages.get(menuItem.target) ?? {
//               id: '',
//               title: '',
//               sections: [],
//             }
//           }
//           allPages={allPages}
//         />
//       </AccordionDetails>
//     </Accordion>
//   );
// }

interface PdfMenuProps {
  menuItem: MenuItem;
  uriTransformer: (uri: string) => string;
}

function PdfMenu({ menuItem, uriTransformer }: PdfMenuProps): JSX.Element {
  const { title, subtitle } = menuItem;

  const id = `${title}=${subtitle}-pdf`;

  const pdfUrl = uriTransformer(menuItem.target);

  return (
    <AccordionSummary
      expandIcon={<OpenInNewIcon />}
      aria-controls="panel1a-content"
      id={`${id}-summary`}
      onClick={(event) => {
        event.preventDefault();
        window.open(pdfUrl);
      }}
    >
      <Stack>
        <Typography>{title}</Typography>
        <Typography variant="body2" sx={{ color: 'GrayText' }}>
          {subtitle}
        </Typography>
      </Stack>
    </AccordionSummary>
  );
}

interface MobilePageMenuProps {
  menuItem: MenuItem;
}

function MobilePageMenu({ menuItem }: MobilePageMenuProps): JSX.Element {
  const params = useParams();
  const encodedUrl = params.encodedUrl ?? '';

  const navigate = useNavigate();
  const pageId = menuItem.target;
  const safeModule = encodeURIComponent(encodedUrl);
  const safePageId = encodeURIComponent(pageId);
  const fullTo = `../${safeModule}?pageId=${safePageId}`;

  return (
    <Accordion disableGutters elevation={0} square>
      <AccordionSummary
        expandIcon={<ArrowForwardIosIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={(event) => {
          event.preventDefault();
          navigate(fullTo);
        }}
      >
        <Stack>
          <Typography>
            <strong>{menuItem.title}</strong>
          </Typography>
          <Typography variant="body2" sx={{ color: 'GrayText' }}>
            {menuItem.subtitle}
          </Typography>
        </Stack>
      </AccordionSummary>
    </Accordion>
  );
}
