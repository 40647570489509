import {
  AnaestheticsLogo,
  CardiologyLogo,
  Covid19Logo,
  DiabetesLogo,
  EmergencyMedicineLogo,
  GastroenterologyLogo,
  HaematologyLogo,
  IpcLogo,
  ItuLogo,
  MedicalLogo,
  ObstetricsAndGynaecologyLogo,
  OphthalmicsLogo,
  OrthodonticsLogo,
  PaediatricsLogo,
  PainManagementLogo,
  PlaceholderLogo,
  RespiratoryLogo,
  SurgicalLogo,
  TheatresLogo,
  VascularLogo,
} from './CategoryIconData';

// const iconColours = ["", {hue: 123, saturation: 123, value: 123}];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const categoryIconDetails = [
  {
    names: ['Blank'],
    colour: 'hsl(198, 40%, 50%)',
    icon: <g />,
  },
  {
    names: ['Anaesthetics'],
    colour: 'hsl(60, 40%, 50%)',
    icon: AnaestheticsLogo,
  },
  {
    names: ['Cardiology'],
    colour: 'hsl(20, 100%, 40%)',
    icon: CardiologyLogo,
  },
  { names: ['COVID 19'], colour: 'hsl(320, 100%, 40%)', icon: Covid19Logo },
  { names: ['Diabetes'], colour: 'hsl(260, 40%, 50%)', icon: DiabetesLogo },
  {
    names: ['Emergency Medicine'],
    colour: 'hsl(220, 100%, 40%)',
    icon: EmergencyMedicineLogo,
  },
  {
    names: ['Gastroenterology'],
    colour: 'hsl(160, 40%, 50%)',
    icon: GastroenterologyLogo,
  },
  {
    names: ['Haematology', 'Haematology & VTE', 'Haematology and VTE'],
    colour: 'hsl(0, 100%, 40%)',
    icon: HaematologyLogo,
  },
  { names: ['IPC'], colour: 'hsl(300, 100%, 40%)', icon: IpcLogo },
  { names: ['ITU'], colour: 'hsl(240, 40%, 50%)', icon: ItuLogo },
  { names: ['Medical'], colour: 'hsl(200, 100%, 40%)', icon: MedicalLogo },
  {
    names: ['Obstetrics & Gynaecology', 'Obstetrics and Gynaecology'],
    colour: 'hsl(140, 40%, 50%)', // hsl(0, 40%, 60%)
    icon: ObstetricsAndGynaecologyLogo,
  },
  {
    names: ['Orthodontics', 'OMFS & Orthodontics', 'OMFS and Orthodontics'],
    colour: 'hsl(200, 40%, 50%)',
    icon: OrthodonticsLogo,
  },
  {
    names: ['Ophthalmics'],
    colour: 'hsl(40, 40%, 50%)',
    icon: OphthalmicsLogo,
  },
  {
    names: ['Paediatrics'],
    colour: 'hsl(180, 100%, 40%)',
    icon: PaediatricsLogo,
  },
  {
    names: ['Pain Management'],
    colour: 'hsl(120, 40%, 50%)',
    icon: PainManagementLogo,
  },
  {
    names: ['Respiratory'],
    colour: 'hsl(60, 100%, 40%)',
    icon: RespiratoryLogo,
  },
  { names: ['Surgical'], colour: 'hsl(20, 40%, 50%)', icon: SurgicalLogo },
  { names: ['Theatres'], colour: 'hsl(300, 40%, 50%)', icon: TheatresLogo },
  {
    names: [
      'Vascular',
      'Vascular Access & IV Therapy',
      'Vascular Access and IV Therapy',
    ],
    colour: 'hsl(260, 100%, 40%)',
    icon: VascularLogo,
  },
];

const placeholderIconDetails = {
  names: [''],
  colour: 'hsl(198, 40%, 50%)',
  icon: PlaceholderLogo,
};

interface CategoryIconProps {
  categoryName: string;
  width: string;
}

export function CategoryIcon({
  categoryName,
  width,
}: CategoryIconProps): JSX.Element {
  const hue = 198;
  const saturation = 40;
  const lightness = 50;

  const baseColour = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  const iconDetails =
    categoryIconDetails.find(({ names }) => names.includes(categoryName)) ??
    placeholderIconDetails;

  const icon = buildTemplateSvgIcon(iconDetails.colour, iconDetails.icon);
  return (
    <div style={{ width, lineHeight: 0 }}>
      {buildTemplateSvgIcon(baseColour, icon)}
    </div>
  );

  //   const categoryLogoPath = `${process.env.PUBLIC_URL}/assets/${categoryName}.svg`;
  //   return <img src={categoryLogoPath} alt="" style={{ width: '64px' }} />;
}

function buildTemplateSvgIcon(
  baseColour: string,
  foregroundSvg: JSX.Element
): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 252 252"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
    >
      <g>
        <clipPath id="_clip1">
          <path
            d="M251.105,195.704C251.105,226.175 226.18,251.104 195.717,251.104L55.388,251.104C24.925,251.104 0,226.175 0,195.704L0,55.388C0,24.925 24.925,0 55.388,0L195.717,0C226.18,0 251.105,24.925 251.105,55.388L251.105,195.704Z"
            clipRule="nonzero"
          />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <rect x="0" y="0" width="252" height="252" fill={baseColour} />
          {foregroundSvg}
        </g>
      </g>
    </svg>
  );
}
