import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import {
  alpha,
  Button,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SearchGuidelinesDialog } from './SearchGuidelinesDialog';

const SearchButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  textTransform: 'none',
}));

export function SearchGuidelines() {
  const [isSearchResultsDialogOpen, setIsSearchResultsDialogOpen] =
    useState(false);

  const theme = useTheme();
  const hideButtonText = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {hideButtonText ? (
        <IconButton
          color="inherit"
          onClick={() => setIsSearchResultsDialogOpen(true)}
        >
          <SearchIcon />
        </IconButton>
      ) : (
        <SearchButton
          onClick={() => setIsSearchResultsDialogOpen(true)}
          startIcon={<SearchIcon />}
          color="inherit"
        >
          Search...
        </SearchButton>
      )}
      <SearchGuidelinesDialog
        open={isSearchResultsDialogOpen}
        handleClose={() => {
          setIsSearchResultsDialogOpen(false);
        }}
      />
    </>
  );
}
