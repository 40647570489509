import {
  TextField,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';

type TitleAndSubtitle = {
  title: string;
  subtitle: string;
};

type TitleEditorProps = {
  open: boolean;
  handleClose: () => void;
  updateTitleAndSubtitle: (title: string, subtitle: string) => void;
  title: string;
  subtitle: string;
};

export function TitleEditorDialog({
  open,
  handleClose,
  title,
  subtitle,
  updateTitleAndSubtitle,
}: TitleEditorProps): JSX.Element {
  const { handleSubmit, control } = useForm<TitleAndSubtitle>();

  const onSubmit = (data: TitleAndSubtitle): void => {
    updateTitleAndSubtitle(data.title, data.subtitle);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Stack>
            <Controller
              name="title"
              control={control}
              defaultValue={title}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Page Title"
                  value={value}
                  margin="normal"
                  variant="standard"
                  fullWidth
                  autoFocus
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="subtitle"
              control={control}
              defaultValue={subtitle}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Sub-Heading (Optional)"
                  value={value}
                  margin="normal"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <IconButton type="reset" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
          <Button
            type="submit"
            onClick={handleClose}
            color="primary"
            variant="contained"
            disableElevation
          >
            OK
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
