/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';

import { SectionDisplayAndEditor } from './SectionDisplayAndEditor';
import {
  GuidelinePages,
  GuidelineSection,
  MenuItem,
} from '../../model/GuidelinePage';
import {
  deleteSection,
  updateMenuItems,
  updateSection,
} from '../../model/GuidelinePagesEditor';

export type ContentDisplayAndEditorProps = {
  pageId: string;
  pages: GuidelinePages;
  setPages: (pages: GuidelinePages) => void;
};

export function ContentDisplayAndEditor({
  pageId,
  pages,
  setPages,
}: ContentDisplayAndEditorProps): JSX.Element {
  const pageToEdit = pages.allPagesById.get(pageId) ?? pages.rootPage;

  const handleUpdateSection = useCallback(
    (updatedSection: GuidelineSection) => {
      const newPages = updateSection(pages, pageId, updatedSection);
      setPages(newPages);
    },
    [pageId, pages, setPages]
  );

  const handleDeleteSection = useCallback(
    (sectionId: string) => {
      const newPages = deleteSection(pages, pageId, sectionId);
      setPages(newPages);
    },
    [pageId, pages, setPages]
  );

  const handleUpdateMenuItems = useCallback(
    (sectionId: string, menuItems: MenuItem[]) => {
      const newPages = updateMenuItems(pages, pageId, sectionId, menuItems);
      setPages(newPages);
    },
    [pageId, pages, setPages]
  );

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <ArticleIcon />
      <Stack
        direction="column"
        spacing={1}
        width="100%"
        alignItems="flex-start"
      >
        <Typography variant="body1" fontWeight="bold">
          Content
        </Typography>
        {pageToEdit.sections.map((section) => (
          <SectionDisplayAndEditor
            section={section}
            key={section.id}
            updateSection={handleUpdateSection}
            deleteSection={handleDeleteSection}
            updateMenuItems={handleUpdateMenuItems}
          />
        ))}
        {/* Add Section button */}
        <Button startIcon={<AddIcon />}>Add a section</Button>
      </Stack>
    </Stack>
  );
}
