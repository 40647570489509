import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import {
  doesTitleIncludeFph,
  doesTitleIncludeWph,
  EnvironmentType,
  removeSiteSpecifierFromName,
  ThemeAndTitleContext,
} from './ThemeAndTitleProvider';
import { guidelinePathToUrlParam } from '../services/GuidelinePathToUrlParam';
import {
  buildGuidelinesByFolder,
  categorizeGuidelines,
} from '../services/GuidelinesIndexUtils';
import { GuidelinesAppBar } from './GuidelinesAppBar';
import { CategoryIcon } from './CategoryIcon';

interface ContentsProps {
  environment?: EnvironmentType;
}

export function Contents({
  environment = 'normal',
}: ContentsProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  const [guidelineEntriesByCategory, setGuidelineEntriesByCategory] = useState(
    new Map<string, GuidelinesIndexEntry[]>()
  );

  const { setThemeFromName, setEnvironment } = useContext(ThemeAndTitleContext);
  useEffect(() => {
    setEnvironment(environment);
    setThemeFromName('');
  }, [environment, setEnvironment, setThemeFromName]);

  useEffect(() => {
    async function getGuidelinesIndex(): Promise<void> {
      setLoading(true);

      try {
        if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
        const guidelinesIndex = await loadGuidelinesIndex();
        const tempGuidelineEntriesByCategory =
          categorizeGuidelines(guidelinesIndex);
        setGuidelineEntriesByCategory(tempGuidelineEntriesByCategory);
      } catch (innerError) {
        const typedError = innerError as { message: string };
        setError(typedError.message);
      } finally {
        setLoading(false);
      }
    }

    getGuidelinesIndex();
  }, [loadGuidelinesIndex]);

  const { appTitle } = useContext(ThemeAndTitleContext);

  const theme = useTheme();
  // Use a dark theme on a mobile device to mimic the previous apps
  const useDarkThemeQuery = theme.breakpoints.down('sm');

  if (loading) {
    return buildPlaceholderCategories();
  }

  if (error !== '') {
    return <li>{error}</li>;
  }

  const categoryElements: JSX.Element[] = buildCategoryElements(
    guidelineEntriesByCategory,
    useDarkThemeQuery
  );

  return (
    <Box
      height="100vh"
      sx={{
        [useDarkThemeQuery]: { backgroundColor: '#404040' },
        flexGrow: 1,
      }}
    >
      <GuidelinesAppBar appTitle={appTitle} showSearch />
      {categoryElements}
    </Box>
  );
}

function buildCategoryElements(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  useDarkThemeQuery: string
) {
  const categoryElements: JSX.Element[] = [];

  guidelineEntriesByCategory.forEach((entriesInCategory, categoryName) => {
    const groupKey = categoryName;
    const entriesByFolder = buildGuidelinesByFolder(entriesInCategory);
    const allCategoryElements: JSX.Element[] = [];
    let isFirst = true;
    entriesByFolder.forEach((entriesInFolder, folderName) => {
      const hasFolderName = folderName.trim().length > 0;
      if (hasFolderName) {
        const folderHeaderKey = `${groupKey}/${folderName}`;
        const topMargin = isFirst ? '0rem' : '0.5rem';
        isFirst = false;
        allCategoryElements.push(
          <Grid
            item
            xs={12}
            key={folderHeaderKey}
            sx={{
              backgroundColor: 'lightgrey',
              [useDarkThemeQuery]: {
                backgroundColor: '#116453',
              },
              marginTop: topMargin,
            }}
          >
            <Typography
              style={{
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
                fontSize: '1.1rem',
              }}
            >
              {folderName}
            </Typography>
          </Grid>
        );
      }

      const buttonElements = entriesInFolder.map((guidelineEntry) =>
        buildGuidelineButton(guidelineEntry, useDarkThemeQuery)
      );

      allCategoryElements.push(...buttonElements);
    });

    categoryElements.push(
      <div key={`${groupKey}-div`}>
        <Accordion
          key={groupKey}
          square
          sx={{
            [useDarkThemeQuery]: { backgroundColor: '#262626', color: 'white' },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ [useDarkThemeQuery]: { color: 'white' } }}
              />
            }
            aria-controls={`${categoryName}-content`}
            id={`${categoryName}-header`}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              {buildCategoryIconAndDefault(categoryName)}
              {/* <CategoryIcon categoryName={categoryName} width="64px" /> */}
              <Typography sx={{ fontSize: '1.6rem' }}>
                {categoryName}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              [useDarkThemeQuery]: {
                backgroundColor: '#404040',
                paddingLeft: 0,
                paddingRight: 0,
              },
              paddingTop: 0,
            }}
          >
            <Grid container>{allCategoryElements}</Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
  return categoryElements;
}

function buildCategoryIconAndDefault(categoryName: string) {
  const categoryLogoPath = `${process.env.PUBLIC_URL}/assets/${categoryName}.svg`;

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <CategoryIcon categoryName="Blank" width="64px" />
      <Typography
        sx={{
          fontSize: '2.7rem',
          color: 'white',
          position: 'absolute',
          inset: 0,
          textAlign: 'center',
          height: 'fit-content',
        }}
      >
        {categoryName.charAt(0)}
      </Typography>
      <img
        src={categoryLogoPath}
        alt=""
        style={{
          width: '64px',
          position: 'absolute',
          inset: 0,
        }}
      />
    </Box>
  );
}

function buildGuidelineButton(
  guidelineEntry: GuidelinesIndexEntry,
  useDarkThemeQuery: string
) {
  const safeUrl = guidelinePathToUrlParam(guidelineEntry.path);

  // TODO: Abstract this out of the core code
  const [nameToDisplay, badgeElement] = getSiteSpecificNameAndBadge(
    guidelineEntry.guidelineName
  );

  return (
    <Grid item xs={12} sm={6} key={guidelineEntry.key}>
      <Button
        component={RouterLink}
        to={safeUrl}
        size="large"
        sx={{
          textTransform: 'none',
          justifyContent: 'flex-start',
          lineHeight: 'normal',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          [useDarkThemeQuery]: {
            color: 'whitesmoke',
            fontSize: '1.1rem',
            fontWeight: 400,
          },
        }}
      >
        {badgeElement}
        {nameToDisplay}
      </Button>
    </Grid>
  );
}

function getSiteSpecificNameAndBadge(
  guidelineName: string
): [string, JSX.Element] {
  const titleIncludesFph = doesTitleIncludeFph(guidelineName);
  const titleIncludesWph = doesTitleIncludeWph(guidelineName);

  let badgeElement = <div />;

  if (titleIncludesFph && !titleIncludesWph) {
    badgeElement = (
      <Chip
        size="small"
        color="frimley"
        label="FP"
        key="FP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  if (titleIncludesWph && !titleIncludesFph) {
    badgeElement = (
      <Chip
        size="small"
        color="wexham"
        label="WP"
        key="WP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  const nameToDisplay = removeSiteSpecifierFromName(guidelineName);

  return [nameToDisplay, badgeElement];
}

function buildPlaceholderCategories(): JSX.Element {
  const widths = [190, 114, 164];
  const placeholderCategories = widths.map((width) => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={{ xs: 1, sm: 2 }}
      paddingLeft={2}
      key={`placeholder-${width}`}
    >
      <Skeleton variant="rectangular" width={64} height={64} />
      <Skeleton variant="rectangular" width={width} height={40} />
    </Stack>
  ));

  return (
    <Fade in style={{ transitionDelay: '300ms' }}>
      <Stack spacing={2.5} paddingTop={2}>
        {placeholderCategories}
      </Stack>
    </Fade>
  );
}
