import { Stack } from '@mui/material';
import { useState, useCallback } from 'react';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { PageHeading } from '../guideline_display/PageHeading';
import { TitleEditorDialog } from './TitleEditorDialog';

export type TitleDisplayAndEditorProps = {
  title: string;
  subtitle: string;
  setTitleAndSubtitle: (title: string, subtitle: string) => void;
};

export function TitleDisplayAndEditor({
  title,
  subtitle,
  setTitleAndSubtitle,
}: TitleDisplayAndEditorProps): JSX.Element {
  const [isTitleEditorDialogOpen, setIsTitleEditorDialogOpen] = useState(false);

  const handleClickOpen = useCallback(
    () => setIsTitleEditorDialogOpen(true),
    []
  );

  const handleClose = useCallback(() => setIsTitleEditorDialogOpen(false), []);

  const updateTitleAndSubtitle = useCallback(
    (newTitle: string, newSubtitle: string) =>
      setTitleAndSubtitle(newTitle, newSubtitle),
    [setTitleAndSubtitle]
  );

  return (
    <>
      <Stack direction="row" spacing={2}>
        <AdUnitsIcon />
        <PageHeading
          heading={title}
          subheading={subtitle}
          showEmptySubHeading
          onClick={handleClickOpen}
        />
      </Stack>
      <TitleEditorDialog
        open={isTitleEditorDialogOpen}
        title={title}
        subtitle={subtitle}
        handleClose={handleClose}
        updateTitleAndSubtitle={updateTitleAndSubtitle}
      />
    </>
  );
}
