import {
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
  ListItemText,
  Menu,
} from '@mui/material';

interface SpecialCharacterDescription {
  character: string;
  description: string;
}
const specialCharacters: SpecialCharacterDescription[] = [
  { character: 'O₂', description: 'Oxygen' },
  { character: '℃', description: 'Degrees C' },
  { character: '→', description: 'Right arrow' },
  { character: '≥', description: 'Greater than or equal' },
  { character: '≤', description: 'Less than or equal' },
  { character: '±', description: 'Plus/minus' },
  { character: '½', description: 'Half' },
  { character: '¼', description: 'One quarter' },
  { character: '¾', description: 'Three quarters ' },
];

interface SpecialCharactersMenuProps {
  anchorEl?: HTMLElement;
  handleClose: () => void;
  handleCharacter: (character: string) => void;
}
export function SpecialCharactersMenu({
  anchorEl,
  handleClose,
  handleCharacter,
}: SpecialCharactersMenuProps) {
  const open = Boolean(anchorEl);

  const menuItems = specialCharacters.map((characterDescription) => (
    <MenuItem
      onClick={() => {
        handleCharacter(characterDescription.character);
      }}
      key={characterDescription.character}
    >
      <ListItemIcon>
        <Typography variant="body2" color="text.secondary">
          {characterDescription.character}
        </Typography>
      </ListItemIcon>
      <ListItemText>{characterDescription.description}</ListItemText>
    </MenuItem>
  ));

  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuList>{menuItems}</MenuList>
    </Menu>
  );
}
