import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  DialogTitle,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Box,
  Skeleton,
  Typography,
  Grid,
  PaletteColor,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import { ResourcePathAndUrl } from '../../services/FileSystemGuidelinesLoader';
import { useGuidelinesLoaderProviderContext } from '../../services/GuidelinesLoaderProviderContext';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import {
  CategoryAndSubfolder,
  getCategoryAndSubfolder,
} from '../../services/GuidelinesIndexUtils';

interface InsertImageDialogProps {
  open: boolean;
  handleClose: () => void;
  insertImage: (imagePath: string) => void;
}

export function InsertImageDialog({
  open,
  handleClose,
  insertImage,
}: InsertImageDialogProps) {
  const [selectedImagePath, setSelectedImagePath] = useState('');
  const [categoryAndSubfolder, setCategoryAndSubfolder] =
    useState<CategoryAndSubfolder>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const guidelinesLoaderProviderContext = useGuidelinesLoaderProviderContext();
  const { fileHandle: guidelineFileHandle } = useGuidelinesProviderContext();

  const primaryColour = useTheme().palette.primary;

  useEffect(() => {
    async function getGuidelinesIndex(): Promise<void> {
      setLoading(true);

      try {
        const { loadGuidelinesIndex } = guidelinesLoaderProviderContext;
        if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
        const guidelinesIndex = await loadGuidelinesIndex();
        const guidelineIndexEntry = guidelinesIndex.find(
          (indexEntry) => indexEntry.fileHandle === guidelineFileHandle
        );
        if (guidelineIndexEntry) {
          const tempCategoryAndSubfolder =
            getCategoryAndSubfolder(guidelineIndexEntry);
          setCategoryAndSubfolder(tempCategoryAndSubfolder);
        }
      } catch (innerError) {
        const typedError = innerError as { message: string };
        setError(typedError.message);
      } finally {
        setLoading(false);
      }
    }

    getGuidelinesIndex();
  }, [guidelineFileHandle, guidelinesLoaderProviderContext]);

  const resourcesUrlsBySourcePath =
    guidelinesLoaderProviderContext.resourcesUrlsBySourcePath ??
    new Map<string, ResourcePathAndUrl[]>();
  const category = categoryAndSubfolder?.category ?? '';
  const resourcesForThisCategory =
    resourcesUrlsBySourcePath.get(category) ?? [];
  const subfolder = categoryAndSubfolder?.subfolder ?? '';
  const imageResourcesToList = resourcesForThisCategory.filter(
    (resource) => isImage(resource) && resource.path.startsWith(subfolder)
  );

  const handleInsertPress = useCallback(() => {
    insertImage(selectedImagePath);
    handleClose();
  }, [handleClose, insertImage, selectedImagePath]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loadingPlaceholders = (
    <Grid container spacing={2} paddingTop={2}>
      {['one', 'two'].map((key) => (
        <Grid item>
          <Skeleton variant="rectangular" width={210} height={118} key={key} />
        </Grid>
      ))}
    </Grid>
  );
  const headingText =
    subfolder.length > 0 ? `${category}/${subfolder}` : category;
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      scroll="paper"
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">Insert Image</DialogTitle>
      <DialogContent>
        {error.length > 0 && <Typography>{error}</Typography>}
        {loading ? (
          loadingPlaceholders
        ) : (
          <>
            <ImageListItem key="Subheader" cols={2}>
              <ListSubheader component="div" sx={{ padding: 0 }}>
                {headingText}
              </ListSubheader>
            </ImageListItem>
            <ImageList variant="masonry" sx={{ margin: 0 }}>
              {imageResourcesToList.map((imageResource) =>
                buildImageListItem(
                  imageResource,
                  subfolder,
                  imageResource.path === selectedImagePath,
                  primaryColour,
                  setSelectedImagePath
                )
              )}
            </ImageList>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleInsertPress}
          color="primary"
          variant="contained"
          disableElevation
          disabled={selectedImagePath.length === 0}
          sx={{ marginLeft: '16px' }}
        >
          Insert
        </Button>
        <IconButton type="reset" onClick={handleClose} color="primary">
          <CloseIcon />
        </IconButton>
        <Box flexGrow={1} />
      </DialogActions>
    </Dialog>
  );
}

function buildImageListItem(
  resourcePathAndUrl: ResourcePathAndUrl,
  subfolder: string,
  isSelected: boolean,
  primaryColour: PaletteColor,
  setSelectedImagePath: (imagePath: string) => void
) {
  const pathInSubfolder = resourcePathAndUrl.path.replace(
    new RegExp(`^${subfolder}\\/`),
    ''
  );
  const filename = pathInSubfolder.split('\\')?.pop()?.split('/')?.pop() ?? '';
  const filenameWithoutExtension =
    filename.substring(0, filename.lastIndexOf('.')) || filename;

  const boxStyle = isSelected
    ? { border: '3px solid', borderColor: primaryColour.main }
    : { border: '1px solid lightgrey', padding: '2px' };

  const barStyle = isSelected ? {} : { margin: '2px' };

  return (
    <Box
      onClick={() =>
        setSelectedImagePath(isSelected ? '' : resourcePathAndUrl.path)
      }
    >
      <ImageListItem key={resourcePathAndUrl.path} sx={{ ...boxStyle }}>
        <img
          src={`${resourcePathAndUrl.url}`}
          alt={resourcePathAndUrl.path}
          loading="lazy"
        />
        <ImageListItemBar
          title={filenameWithoutExtension}
          subtitle={pathInSubfolder}
          sx={barStyle}
        />
      </ImageListItem>
    </Box>
  );
}

function isImage(resourcePathAndUrl: ResourcePathAndUrl): boolean {
  const filename =
    resourcePathAndUrl.path.split('\\')?.pop()?.split('/')?.pop() ?? '';
  const fileExtension = filename.split('.').pop()?.toLocaleLowerCase() ?? '';
  const imageExtensions = ['svg', 'png', 'jpg', 'jpeg', 'gif', 'apnp', 'webp'];
  const isImageType = imageExtensions.includes(fileExtension);

  return isImageType;
}
