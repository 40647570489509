import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const boxShadowStyle: React.CSSProperties = {
  boxShadow:
    '0 1px 1px 0 rgba(0, 28, 36, 0.3),1px 1px 1px 0 rgba(0, 28, 36, 0.15),-1px 1px 1px 0 rgba(0, 28, 36, 0.15)',
  borderRadius: '0',
  borderTop: '1px solid #eaeded',
  backgroundColor: '#f9f9f9',
  marginBottom: '1rem',
};

interface ActionStepBoxProps {
  headingText: string;
  subHeading: JSX.Element;
  contents: JSX.Element;
  showSuccessTick?: Boolean;
}
export function ActionStepBox({
  headingText,
  subHeading,
  contents,
  showSuccessTick = false,
}: ActionStepBoxProps): JSX.Element {
  return (
    <Box sx={boxShadowStyle}>
      <Box sx={{ padding: '1rem' }}>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid item>
            <Typography variant="h6" marginBottom="1rem">
              {headingText}
            </Typography>
          </Grid>
          {showSuccessTick && (
            <Grid item>
              <CheckCircleIcon color="success" />
            </Grid>
          )}
        </Grid>
        {subHeading}
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '1rem' }}>{contents}</Box>
    </Box>
  );

  //   <Box sx={boxShadowStyle}>
  //     <Box sx={{ padding: '1rem' }}>
  //       <Typography variant="h6" marginBottom="1rem">
  //         Step 2 - Choose the name for the guideline
  //       </Typography>
  //       <Typography>
  //         This is the name that will be displayed to the user. Choose
  //         something simple like <strong>Hyperglycaemia in Stroke</strong> or{' '}
  //         <strong>Asthma (Acute)</strong>
  //       </Typography>
  //     </Box>
  //     <Box sx={{ backgroundColor: 'white', padding: '1rem' }}>
  //       <TextField
  //         required
  //         id="simple-guideline-name"
  //         label="Display Name"
  //         fullWidth
  //         value={simpleGuidelineName}
  //         onChange={(event) => setSimpleGuidelineName(event.target.value)}
  //       />
  //     </Box>
  //   </Box>
  //   <Box sx={boxShadowStyle}>
  //     <Box sx={{ padding: '1rem' }}>
  //       <Typography variant="h6" marginBottom="1rem">
  //         Step 3 - Convert the Word document
  //       </Typography>
  //       <Typography>
  //         This will upload the Word file, and convert it to the format for use
  //         in the guidelines app.
  //       </Typography>
  //     </Box>
  //     <Box sx={{ backgroundColor: 'white', padding: '1rem' }}>
  //       {buildReadyToConvertProgress(filesToUpload, simpleGuidelineName)}
  //       <Button
  //         variant="contained"
  //         sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}
  //         disabled={!canUploadAndConvert || isConversionInProgress}
  //         onClick={handleUploadAndConvertClick}
  //       >
  //         Upload and Convert
  //       </Button>
  //       {buildConversionProgress(uploadAndConvertState)}
  //       {conversionOutputDownloadUrl && (
  //         <Button variant="contained" onClick={handleDownloadClick}>
  //           Download
  //         </Button>
  //       )}
  //     </Box>
  //   </Box>
}
