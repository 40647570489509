import { Stack, Button, IconButton } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { MarkdownTextField } from '../guideline_editor/MarkdownTextField';
import { FormattingHelpDialog } from './FormattingHelpDialog';
import { useUndoableState } from '../../services/useUndoableState';

interface MarkdownEditorProps {
  markdown: string;
  setMarkdown: (updatedMarkdown: string) => void;
}

export function MarkdownEditor({
  markdown,
  setMarkdown,
}: MarkdownEditorProps): JSX.Element {
  const textInput = useRef<HTMLInputElement>();

  const {
    state: workingText,
    setState: setWorkingText,
    // resetState: resetWorkingText,
    index: textStateIndex,
    lastIndex: textStateLastIndex,
    goBack: undoTextChange,
    goForward: redoTextChange,
  } = useUndoableState(markdown);
  const canUndo = textStateIndex > 0;
  const canRedo = textStateIndex < textStateLastIndex;

  const handleSave = useCallback(() => {
    setMarkdown(workingText);
  }, [setMarkdown, workingText]);

  const handleClose = useCallback(() => {
    setWorkingText(markdown);
  }, [markdown, setWorkingText]);

  const handleUndo = useCallback(() => {
    undoTextChange();
  }, [undoTextChange]);

  const handleRedo = useCallback(() => {
    redoTextChange();
  }, [redoTextChange]);

  const [isFormattingHelpDialogOpen, setIsFormattingHelpDialogOpen] =
    useState(false);
  return (
    <>
      <FormattingHelpDialog
        open={isFormattingHelpDialogOpen}
        handleClose={() => setIsFormattingHelpDialogOpen(false)}
      />
      <MarkdownTextField
        multiline
        maxRows={16}
        value={workingText}
        fullWidth
        margin="normal"
        variant="outlined"
        sx={{ backgroundColor: 'white' }}
        inputRef={textInput}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setWorkingText(event.target.value)
        }
        onUndoPress={handleUndo}
        onRedoPress={handleRedo}
        undoEnabled={canUndo}
        redoEnabled={canRedo}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <div>
          <Button variant="contained" component="span" onClick={handleSave}>
            Save
          </Button>
          <IconButton component="span" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Button onClick={() => setIsFormattingHelpDialogOpen(true)}>
          Formatting help
        </Button>
      </Stack>
    </>
  );
}
