import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PreviewIcon from '@mui/icons-material/Preview';
import HelpIcon from '@mui/icons-material/Help';
import { Divider, Drawer, List, Toolbar } from '@mui/material';
import { ListItemLink } from '../admin/ListItemLink';

export type AuthoringNavigationDrawerProps = {
  drawerWidth: number;
};

export function AuthoringNavigationDrawer({
  drawerWidth,
}: AuthoringNavigationDrawerProps): JSX.Element {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItemLink
          to="/authoring"
          primary="Preview Guidelines"
          icon={<PreviewIcon />}
        />
        <ListItemLink
          to="/authoring/newguideline"
          primary="New Guideline"
          icon={<AddCircleIcon />}
        />
        <ListItemLink
          to="/authoring/importworddocument"
          primary="Import Word Document"
          icon={<FileOpenIcon />}
        />
        <ListItemLink
          to="/authoringhelp"
          primary="Help"
          icon={<HelpIcon />}
          openInNewTab
        />
      </List>
    </Drawer>
  );
}
