import { TemplateGuideline } from '../model/template_guideline';

export function getGuidelineTemplates(): TemplateGuideline[] {
  return [
    { title: 'Blank', description: '', template: '' },
    {
      title: 'Link to PDF',
      description: 'A guideline that links to a PDF',
      template: `>> View PDF [target:{title}.pdf]
<<`,
      helpMarkdown: `#### Next Steps
  
  1. Press 'Create' and then select the folder to save the result
  2. Place '{title}.pdf' in the same folder`,
    },
    // {
    //   title: 'Third',
    //   description: 'Description of the third template guideline',
    //   template: '',
    // },
    // {
    //   title: 'Fourth',
    //   description: 'Description of the fourth template guideline',
    //   template: '',
    // },
    // {
    //   title: 'Fifth',
    //   description: 'Description of the fifth template guideline',
    //   template: '',
    // },
    {
      title: 'Summary Guideline',
      description:
        'A simple summary of the main steps for investigating and managing a condition. Equivalent to 1 to 2 printed pages.',
      template:
        '# Guideline Title \n## Signs & Symptoms\n\n _Describe how to identify the condition._\n\n## Causes \n\n * Normal cause of this condition\n* Another cause\n\n## Investigation\n\nThe investigations that should be performed to confirm the diagnosis and guide management of the condition.\n\n',
    },
  ];
}
