import {
  Tabs,
  Tab,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Breakpoint,
  Toolbar,
} from '@mui/material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useState } from 'react';
import { Guideline } from '../guideline_display/Guideline';
import { TabPanel } from './TabPanel';
import { GuidelineLoader } from '../GuidelineLoader';
import { VisualGuidelineEditor } from './VisualGuidelineEditor';
import { TextGuidelineEditor } from './TextGuidelinesEditor';
import { AutoSaveUpdatedGuideline } from './AutoSaveUpdatedGuideline';
import { SaveStatus } from './SaveStatus';
import { ErrorBox } from './ErrorBox';
import { NavigationBreadcrumbs } from '../guideline_display/NavigationBreadcrumbs';
// import { UndoRedoUpdatedGuideline } from './UndoRedoUpdatedGuideline';

export function EditableGuideline() {
  const [tabIndex, setTabIndex] = useState(0);
  const [displaySize, setDisplaySize] = useState<Breakpoint>('lg');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<Error>();
  // const [undoRedoIndex, setUndoRedoIndex] = React.useState(0);
  // const [lastUndoRedoIndex, setLastUndoRedoIndex] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleDisplaySize = (
    event: React.MouseEvent<HTMLElement>,
    newDisplaySize: Breakpoint | null
  ) => {
    setDisplaySize(newDisplaySize ?? 'lg');
  };

  // const handleUndo = useCallback(() => {
  //   const canUndo = undoRedoIndex > 0;
  //   if (canUndo) {
  //     setUndoRedoIndex(undoRedoIndex - 1);
  //   }
  // }, [undoRedoIndex]);

  // const handleRedo = useCallback(() => {
  //   const canRedo = undoRedoIndex < lastUndoRedoIndex;
  //   if (canRedo) {
  //     setUndoRedoIndex(undoRedoIndex + 1);
  //   }
  // }, [lastUndoRedoIndex, undoRedoIndex]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <div style={{ width: '79px' }}>
            <SaveStatus isSaving={isSaving} />
          </div>
          {/* <IconButton onClick={handleUndo} disabled={undoRedoIndex === 0}>
            <UndoIcon />
          </IconButton>
          <IconButton
            onClick={handleRedo}
            disabled={undoRedoIndex === lastUndoRedoIndex}
          >
            <RedoIcon />
          </IconButton> */}
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Preview" />
            <Tab label="Visual Editor" />
            <Tab label="Text Editor" />
          </Tabs>
          <ToggleButtonGroup
            value={displaySize}
            exclusive
            onChange={handleDisplaySize}
            size="small"
          >
            <ToggleButton value="lg">
              <DesktopWindowsIcon />
            </ToggleButton>
            <ToggleButton value="xs">
              <PhoneAndroidIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Toolbar>
      </Box>
      {error && <ErrorBox error={error} />}
      <GuidelineLoader>
        <AutoSaveUpdatedGuideline
          setIsSaving={setIsSaving}
          setSaveError={setError}
        />
        {/* <UndoRedoUpdatedGuideline
          undoRedoIndex={undoRedoIndex}
          setUndoRedoIndex={setUndoRedoIndex}
          setLastUndoRedoIndex={setLastUndoRedoIndex}
        /> */}
        <TabPanel value={tabIndex} index={0}>
          <Container maxWidth={displaySize}>
            <Guideline />
          </Container>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Container maxWidth={displaySize}>
            <div style={{ marginTop: '-1.5rem', marginBottom: '0.5rem' }}>
              <NavigationBreadcrumbs />
            </div>
            <VisualGuidelineEditor />
          </Container>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <TextGuidelineEditor />
        </TabPanel>
      </GuidelineLoader>
    </Box>
  );
}
