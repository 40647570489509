import { forwardRef } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs, Chip, Link, Stack } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useHref,
} from 'react-router-dom';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import './NavigationBreadcrumbs.css';

interface PageNameAndId {
  pageName: string;
  id: string;
}

export function NavigationBreadcrumbs() {
  const { pageId } = useGuidelinesProviderContext();
  const baseUrl = useHref('.');

  if (!pageId) {
    return <div />;
  }

  const pageIdsFromPath = pageId.split('/');
  const pagesToHere = pageIdsFromPath.map<PageNameAndId>(
    (pageIdPart, index) => {
      const pageIdsToHere = pageIdsFromPath.slice(0, index + 1);
      const pageIdForHere = index === 0 ? '' : pageIdsToHere.join('/');
      // Remove the -0 section ID from the end of the page ID
      const cleanPageId = pageIdForHere.replaceAll(/(.*)-\d*/g, '$1');
      const id = encodeURIComponent(cleanPageId);
      const pageName = pageIdPart.replaceAll(/(.*)-\d*/g, '$1');
      return {
        pageName,
        id,
      };
    }
  );

  const breadcrumbLinks = pagesToHere.map((pageNameAndId, index) => {
    const isFirst = index === 0;
    const isLast = index === pagesToHere.length - 1;

    const icon = isFirst ? (
      <HomeIcon fontSize="small" sx={{ mr: 0.5 }} />
    ) : undefined;
    const label = truncateString(pageNameAndId.pageName, 50);
    const href = isFirst ? baseUrl : `${baseUrl}?pageId=${pageNameAndId.id}`;

    // No link on the last breadcrumb
    if (isLast)
      return (
        <Chip
          label={label}
          size="small"
          sx={{ marginY: 0.5 }}
          variant="outlined"
          key={pageNameAndId.id}
        />
      );

    const routerLink = getRouterLink(href);

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        component={routerLink}
        underline="hover"
        sx={{ display: 'flex', alignItems: 'center' }}
        color="inherit"
        key={pageNameAndId.id}
      >
        <Chip
          icon={icon}
          label={label}
          clickable
          size="small"
          sx={{ marginY: 0.5 }}
        />
      </Link>
    );
  });

  const haveBreadcrumbsToShow = breadcrumbLinks.length > 1;

  return haveBreadcrumbsToShow ? (
    <Stack direction="column" alignItems="center">
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginY: 1 }}>
        {breadcrumbLinks}
      </Breadcrumbs>
    </Stack>
  ) : (
    <div />
  );
}

function getRouterLink(href: string) {
  return forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
    (itemProps, ref) => <RouterLink to={href} ref={ref} {...itemProps} />
  );
}

function truncateString(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}…`;
  }
  return text;
}
