import { Button, Typography, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  OperationState,
  UploadAndConversionState,
  uploadAndConvertFile,
} from '../../services/AwsWordConversion';
import { DropZoneFullScreenOverlay } from './DropZoneFullScreenOverlay';
import { InlineDropZoneBox } from './InlineDropZoneBox';
import { ActionStepBox } from './ActionStepBox';
import { WordImportConversionProgress } from './WordImportConversionProgress';
import { WordImportFileDisplay } from './WordImportFileDisplay';
import { ErrorBox } from './ErrorBox';

export function WordImporter(): JSX.Element {
  const emptyUploadAndConversionState = {
    upload: OperationState.NotStarted,
    conversion: OperationState.NotStarted,
  } as UploadAndConversionState;

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [simpleGuidelineName, setSimpleGuidelineName] = useState('');
  const [uploadAndConvertState, setUploadAndConvertState] = useState(
    emptyUploadAndConversionState
  );
  const [conversionOutputDownloadUrl, setConversionOutputDownloadUrl] =
    useState('');
  const [unexpectedError, setUnexpectedError] = useState<Error>();

  // These are the properties that make drag and drop work
  const { open, isDragActive, getRootProps } = useDropzone({
    accept:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDropAccepted: setFilesToUpload,
  });
  const dragAndDropProps = getRootProps();

  const handleUploadAndConvertClick = useCallback(async () => {
    try {
      await uploadAndConvertFile(
        filesToUpload[0],
        `${simpleGuidelineName}.docx`,
        setUploadAndConvertState,
        setConversionOutputDownloadUrl
      );
    } catch (error: any) {
      setUnexpectedError(error as Error);
    }
  }, [filesToUpload, simpleGuidelineName]);

  const handleDownloadClick = useCallback(() => {
    const link = document.createElement('a');
    link.download = '';
    link.href = conversionOutputDownloadUrl;
    link.click();
  }, [conversionOutputDownloadUrl]);

  const haveFileToUpload = filesToUpload.length > 0;
  const haveGuidelineName = simpleGuidelineName.trim().length > 0;
  const canUploadAndConvert = haveFileToUpload && haveGuidelineName;
  const fileToUpload = filesToUpload.length > 0 ? filesToUpload[0] : undefined;
  const hasConversionStarted =
    uploadAndConvertState.upload !== OperationState.NotStarted;

  return (
    <div {...dragAndDropProps} style={{ maxWidth: '800px' }}>
      <DropZoneFullScreenOverlay
        dropZoneProps={dragAndDropProps}
        isDragActive={isDragActive}
      />
      <Typography variant="h5" noWrap component="div" marginBottom="1.5rem">
        Import Word Document
      </Typography>
      {unexpectedError && <ErrorBox error={unexpectedError} />}
      <ActionStepBox
        headingText="Step 1 - Choose the Word file to convert"
        subHeading={<InlineDropZoneBox openFileDialog={open} />}
        contents={<WordImportFileDisplay filesToUpload={filesToUpload} />}
        showSuccessTick={fileToUpload !== undefined}
      />
      <ActionStepBox
        headingText="Step 2 - Choose the name for the guideline"
        subHeading={
          <Typography>
            This is the name that will be displayed to the user. Choose
            something simple like <strong>Hyperglycaemia in Stroke</strong> or{' '}
            <strong>Asthma (Acute)</strong>
          </Typography>
        }
        contents={
          <TextField
            required
            id="simple-guideline-name"
            label="Display Name"
            fullWidth
            value={simpleGuidelineName}
            onChange={(event) => setSimpleGuidelineName(event.target.value)}
          />
        }
        showSuccessTick={simpleGuidelineName.trim().length > 0}
      />
      <ActionStepBox
        headingText="Step 3 - Convert the Word document"
        subHeading={
          <Typography>
            This will upload the Word file, and convert it to the format for use
            in the guidelines app.
          </Typography>
        }
        contents={
          <>
            <WordImportConversionProgress
              fileToUpload={fileToUpload}
              guidelineName={simpleGuidelineName}
              isUploadAndConvertButtonDisabled={
                !canUploadAndConvert || hasConversionStarted
              }
              handleUploadAndConvertClick={handleUploadAndConvertClick}
              uploadAndConvertState={uploadAndConvertState}
            />
            {conversionOutputDownloadUrl && (
              <Button variant="contained" onClick={handleDownloadClick}>
                Download
              </Button>
            )}
          </>
        }
      />
    </div>
  );
}
