import './SectionContent.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import deepmerge from 'deepmerge';
import { rehype } from 'rehype';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';

type SectionContentProps = {
  content: string;
};

const schema = deepmerge(defaultSchema, {
  tagNames: ['style'],
  attributes: { '*': ['style', 'className'] },
});

export function SectionContent({ content }: SectionContentProps): JSX.Element {
  const { uriTransformer } = useGuidelinesProviderContext();

  const sanitisedMarkdown = rehype()
    .data('settings', { fragment: true })
    .use(rehypeSanitize, schema)
    .processSync(content)
    .toString();

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      transformImageUri={uriTransformer}
    >
      {sanitisedMarkdown}
    </ReactMarkdown>
  );
}
