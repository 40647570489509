import { Box, Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { buildFileSystemGuidelinesProvider } from '../../services/FileSystemGuidelinesLoaderProvider';
import {
  EmptyGuidelineLoaderProvider,
  GuidelinesLoaderProvider,
} from '../../services/GuidelinesLoaderProvider';
import { GuidelinesLoaderProviderContext } from '../../services/GuidelinesLoaderProviderContext';
import { ThemeAndTitleProvider } from '../ThemeAndTitleProvider';
import { AuthoringAppBar } from './AuthoringAppBar';
import { AuthoringNavigationDrawer } from './AuthoringNavigationDrawer';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export function AuthoringDashboard(): JSX.Element {
  const [localRepositoryDirectoryHandle, setLocalRepositoryDirectoryHandle] =
    useState<FileSystemDirectoryHandle>();
  const [guidelinesProvider, setGuidelinesProvider] =
    useState<GuidelinesLoaderProvider>(EmptyGuidelineLoaderProvider);
  const [isGuidelinesProviderReady, setIsGuidelinesProviderReady] =
    useState(false);
  const desktopWidth = `900px`;
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (localRepositoryDirectoryHandle) {
      const buildProvider = async (): Promise<void> => {
        setGuidelinesProvider(
          await buildFileSystemGuidelinesProvider(
            localRepositoryDirectoryHandle
          )
        );
        setIsGuidelinesProviderReady(true);
      };
      buildProvider();
    }
  }, [localRepositoryDirectoryHandle, key]);

  const drawerWidth = 240;
  const backgroundColor = '#f0f0f0';
  // calc(100% - ${drawerWidth}px)

  return (
    <Container
      key={key}
      maxWidth="md"
      sx={{
        width: { desktopWidth },
        marginLeft: `${drawerWidth}px`,
        backgroundColor: { backgroundColor },
        marginTop: '1rem',
      }}
    >
      <AuthoringAppBar
        title="Clinical Guidelines Authoring"
        drawerWidthInPx={drawerWidth}
        onRefresh={() => setKey(key + 1)}
      />
      <AuthoringNavigationDrawer drawerWidth={drawerWidth} />

      <Box>
        <Offset />
        <ThemeAndTitleProvider>
          <Box
            component="main"
            sx={{
              backgroundColor: { backgroundColor },
            }}
          >
            <GuidelinesLoaderProviderContext.Provider
              value={guidelinesProvider}
            >
              <Outlet
                context={{
                  localRepositoryDirectoryHandle,
                  setLocalRepositoryDirectoryHandle,
                  isGuidelinesProviderReady,
                }}
              />
            </GuidelinesLoaderProviderContext.Provider>
          </Box>
        </ThemeAndTitleProvider>
      </Box>
    </Container>
  );
}

type AuthoringContextType = {
  localRepositoryDirectoryHandle: FileSystemDirectoryHandle;
  setLocalRepositoryDirectoryHandle: (
    handle: FileSystemDirectoryHandle
  ) => void;
  isGuidelinesProviderReady: boolean;
};

export function useAuthoringContext() {
  return useOutletContext<AuthoringContextType>();
}
