import { buildGuidelinesMap } from '../guideline_parser/GuidelineParser';
import { GuidelinePage, GuidelinePages } from '../model/GuidelinePage';

export interface GuidelinesProvider {
  guidelines: GuidelinePages;
  setGuidelines: (guidelines: GuidelinePages) => void;
  uriTransformer: (uri: string) => string;
  fileHandle?: FileSystemFileHandle;
  pageId?: string;
}

export interface GuidelinesAndSource {
  guideline: GuidelinePages;
  fileHandle?: FileSystemFileHandle;
}

const emptyGuidelinePage: GuidelinePage = {
  id: 'root',
  title: '',
  sections: [],
};
const guidelinesMap = buildGuidelinesMap([emptyGuidelinePage]);
export const emptyGuidelinePages: GuidelinePages = {
  rootPage: emptyGuidelinePage,
  allPagesById: guidelinesMap,
};

export const EmptyGuidelinesProvider: GuidelinesProvider = {
  guidelines: emptyGuidelinePages,
  setGuidelines: () => {},
  uriTransformer: (uri: string) => uri,
};
