import {
  findResourceUrlsBySourcePath,
  ResourcePathAndUrl,
} from './FileSystemGuidelinesLoader';

const categoryFromPathRegex = /(.*?)\/.*/;

export function buildUriTransformerFactory(baseUrl: string) {
  /**
   * Translates relative URIs to absolute on the guidelines server.
   * Supplied to ReactMarkdown as transformImageUri.
   */
  return function getUriTransformer(
    sourcePath: string
  ): (uri: string) => string {
    // Guard - hit in testing
    if (!(sourcePath && sourcePath.trim())) {
      return (uri: string): string => uri;
    }

    const [, moduleName] = sourcePath.match(categoryFromPathRegex) ?? ['', ''];

    return (uri: string): string => {
      const needsBaseUrl = !uri.startsWith('http');

      if (needsBaseUrl) {
        return `${baseUrl}${moduleName}/${uri}`;
      }
      return uri;
    };
  };
}

export interface UriTransformerFactory {
  getUriTransformer(sourcePath: string): (uri: string) => string;
  resourcesUrlsBySourcePath: Map<string, ResourcePathAndUrl[]>;
}
export async function buildFileSystemUriTransformerFactory(
  rootPath: FileSystemDirectoryHandle
): Promise<UriTransformerFactory> {
  const resourcesUrlsBySourcePath = await findResourceUrlsBySourcePath(
    rootPath
  );

  const getUriTransformer = (sourcePath: string) => {
    // Guard - hit in testing
    if (!(sourcePath && sourcePath.trim())) {
      return (uri: string): string => uri;
    }

    return (uri: string): string => {
      const needsBaseUrl = !uri.startsWith('http');

      if (needsBaseUrl) {
        const [, moduleName] = sourcePath.match(categoryFromPathRegex) ?? [
          '',
          '',
        ];

        const resources = resourcesUrlsBySourcePath.get(moduleName);
        if (resources) {
          const resource = resources.find(
            // Using 'endWith' rather than '===' as uri can have './' at the start
            (resourcePathAndUrl) => uri.endsWith(resourcePathAndUrl.path)
          );
          if (resource) {
            return resource.url;
          }
        }
      }
      return uri;
    };
  };

  return { getUriTransformer, resourcesUrlsBySourcePath };
}
