import {
  GuidelinePages,
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from '../model/GuidelinePage';

export function pagesToMarkdown(pages: GuidelinePages): string {
  try {
    return pageToMarkdown(pages.rootPage, pages.allPagesById);
  } catch (error: any) {
    throw new Error(`Can't generate markdown: ${error.message}`);
  }
}

/**
 * Converts a page, and its subpages, to markdown. To convert an entire guideline
 * to markdown use pagesToMarkdown.
 *
 * @param page
 * @param pagesById The map of all pages in the guideline.
 * @param inferredTitle The title of the menu to get to this page. Inferred as the title of the sub-page.
 * @param inferredSubtitle The subtitle of the menu to get to this page. Inferred as the subheading of the sub-page.
 * @returns The markdown
 */
export function pageToMarkdown(
  page: GuidelinePage,
  pagesById: Map<string, GuidelinePage>,
  inferredTitle?: string,
  inferredSubtitle?: string
): string {
  const pageHeading = buildPageHeading(page, inferredTitle, inferredSubtitle);

  const sectionsMarkdown = page.sections.map((section, index) => {
    const isFirstSection = index === 0;
    return sectionToMarkdown(section, pagesById, isFirstSection);
  });
  const combinedSectionsMarkdown = sectionsMarkdown.join('');

  const pageMarkdown = `${pageHeading}${combinedSectionsMarkdown}`;
  return `${pageMarkdown.trimEnd()}\n`;
}

function buildPageHeading(
  page: GuidelinePage,
  inferredTitle?: string,
  inferredSubtitle?: string
): string {
  // const pageHasHeading = page.title.length > 0 || page.subheading;
  // if (!pageHasHeading) {
  //   return '';
  // }

  const subHeading = page.subheading
    ? ` [${page.subheading.replaceAll('\n', '\\n')}]`
    : '';
  const pageHeading = `# ${page.title}${subHeading}\n\n`;

  const inferredSubHeading = inferredSubtitle ? ` [${inferredSubtitle}]` : '';
  const inferredSubHeadingEscaped = inferredSubHeading.replaceAll('\n', '\\n');
  const inferredHeading = inferredTitle
    ? `# ${inferredTitle}${inferredSubHeadingEscaped}\n\n`
    : '';

  const shouldUseHeading = pageHeading !== inferredHeading;
  const headingToUse = shouldUseHeading ? pageHeading : '';

  return headingToUse;
}

function sectionToMarkdown(
  section: GuidelineSection,
  pagesById: Map<string, GuidelinePage>,
  hideEmptySectionHeader: boolean
): string {
  const sectionHeader = buildSectionHeader(
    hideEmptySectionHeader,
    section.header,
    section.footer,
    section.color
  );
  const content = section.content.trimEnd();

  const subPagesMarkdown: string[] =
    section.menuItems?.map((menuItem) => {
      const subPageMarkdown = buildSubPage(menuItem, pagesById);
      return subPageMarkdown;
    }) ?? [];

  const contentToAdd = content.length > 0 ? `${content}\n\n` : '';
  return ''.concat(sectionHeader, contentToAdd, ...subPagesMarkdown);
}

function buildSectionHeader(
  hideEmptySectionHeader: boolean,
  header?: string,
  footer?: string,
  color?: string
): string {
  const headerText = header ? ` ${header}` : '';
  const footerText = footer ? ` [footer:${footer}]` : '';
  const colorText = color ? ` [color:${color}]` : '';
  if (
    hideEmptySectionHeader &&
    headerText.length === 0 &&
    footerText.length === 0 &&
    colorText.length === 0
  ) {
    return '';
  }
  return `##${headerText}${footerText}${colorText}\n\n`;
}

function buildSubPage(
  menuItem: MenuItem,
  pagesById: Map<string, GuidelinePage>
): string {
  const { title, subtitle, color, targetType, target } = menuItem;
  const subtitleText = subtitle ? `[${subtitle}]` : '';
  const colorText = color ? `[color:${color}]` : '';
  const targetText = targetType !== 'page' ? `[target:${target}]` : '';

  const subPageHeading =
    `>> ${title} ${targetText}${colorText}${subtitleText}`.trim();
  let pageContent = '';

  switch (targetType) {
    case 'page':
      {
        // find page for id
        const pageId = target;
        const subPage = pagesById.get(pageId);
        if (subPage) {
          pageContent = pageToMarkdown(
            subPage,
            pagesById,
            menuItem.title,
            menuItem.subtitle
          );
        }
      }
      break;

    case 'pdf':
    case 'flowchart':
    case 'other':
      break;

    case 'guideline':
    case 'microguide':
    default:
      throw new Error('Not implemented');
    //
  }

  const pageContentToAdd = pageContent.length > 0 ? `\n${pageContent}` : '';
  const subPageMarkdown = `${subPageHeading}\n${pageContentToAdd}<<\n`;

  return subPageMarkdown;
}
