// import { Stack } from '@mui/material';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { GuidelinePageEditor } from '../guideline_editor/GuidelinePageEditor';

export function VisualGuidelineEditor(): JSX.Element {
  const { guidelines, setGuidelines, pageId } = useGuidelinesProviderContext();

  // If no page ID is passed then use the root page
  const pageIdToUse = pageId ?? guidelines.rootPage.id;

  return (
    <GuidelinePageEditor
      pageId={pageIdToUse}
      pages={guidelines}
      setPages={setGuidelines}
    />
  );
}
