import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

type PageHeadingProps = {
  heading: string;
  subheading?: string;
  showEmptySubHeading?: boolean;
  onClick?: () => void;
};

export function PageHeading({
  heading,
  subheading,
  showEmptySubHeading,
  onClick,
}: PageHeadingProps): JSX.Element {
  const reactOnClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);
  const showSubHeading =
    showEmptySubHeading || (subheading && subheading.trim().length > 0);
  return (
    <Stack direction="column" width="100%">
      <Stack
        direction="row"
        width="100%"
        spacing={0}
        sx={{
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          flexGrow: 1,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          align="center"
          padding="0.5rem"
          color="inherit"
          onClick={() => reactOnClick()}
          sx={{
            flexGrow: 1,
          }}
        >
          {heading}
        </Typography>
      </Stack>
      {showSubHeading && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            background: 'lightgrey',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
          onClick={() => reactOnClick()}
        >
          {subheading}
        </Typography>
      )}
    </Stack>
  );
}
