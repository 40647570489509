import { Button, ListItem, ListItemButton, ListItemText } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams,
} from 'react-router-dom';

interface SubPageListItemLinkProps {
  primary: string;
  secondary?: string;
  pageId: string;
  // eslint-disable-next-line react/require-default-props
  openInNewTab?: boolean;
  handleClickOnText: () => void;
}

export function SubPageListItemLink({
  primary,
  secondary,
  pageId,
  openInNewTab = false,
  handleClickOnText,
}: SubPageListItemLinkProps): JSX.Element {
  const targetProp = openInNewTab ? '_blank' : '';
  const relProp = openInNewTab ? 'noopener noreferrer' : '';
  const safePageId = encodeURIComponent(pageId);

  const params = useParams();
  const encodedUrl = params.encodedUrl ?? '';

  const fullTo = `../${encodedUrl}?pageId=${safePageId}`;

  const routerLink = getRouterLink(fullTo, targetProp, relProp);

  return (
    <ListItem
      divider
      disablePadding
      secondaryAction={
        <Button endIcon={<OpenInNewIcon />} component={routerLink}>
          Open
        </Button>
      }
    >
      <ListItemButton
        onClick={handleClickOnText}
        sx={{
          paddingY: 0,
        }}
      >
        <ListItemText
          sx={{
            marginTop: 0,
          }}
          primary={primary}
          secondary={secondary}
        />
      </ListItemButton>
    </ListItem>
  );
}

function getRouterLink(to: string, targetProp: string, relProp: string) {
  return forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
    (itemProps, ref) => (
      <RouterLink
        to={to}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...itemProps}
        role={undefined}
        target={targetProp}
        rel={relProp}
      />
    )
  );
}
