import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Divider, Drawer, List, Toolbar } from '@mui/material';
import { ListItemLink } from './ListItemLink';

export function NavigationDrawer(): JSX.Element {
  const drawerWidth = 240;
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItemLink
          to="/admin/newguideline"
          primary="New Guideline"
          icon={<AddCircleIcon />}
        />
        <ListItemLink
          to="/local"
          primary="Open Folder"
          openInNewTab
          icon={<FolderOpenIcon />}
        />
        <ListItemLink
          to="/admin/reports"
          primary="Reports"
          icon={<AssessmentIcon />}
        />
        <ListItemLink
          to="/admin/importworddocument"
          primary="Import Word Document"
          icon={<FileOpenIcon />}
        />
      </List>
    </Drawer>
  );
}
