export const formattingHelpMarkdown = `# Formatting help

The guidelines platform uses Markdown for formatting. Here are the basics. See the [complete syntax](https://guidelines.fhft.nhs.uk/authoringhelp/Authoring-Using_Markdown).

---

**Bold**

\`Give a **maximum** of two repeat boluses\`

- Give a **maximum** of two repeat boluses

---

**Italics**

\`_Briefings held ICC 5th floor_\`

- _Briefings held ICC 5th floor_

---

**Lists**

\`\`\`
How to do lists:

* Spring
* Summer
* Other seasons:
  * Autumn
  * Winter
\`\`\`

\`\`\`
1. First
2. Second
3. Third
\`\`\`

_The blank line before the list is important._

---

**Links**

\`Visit the [Frimley Health Guidelines](https://guidelines.fhft.nhs.uk).\`

---

**Images**

\`<img src="media/image.jpg" style="width:100%" />\`

---

**Sub-Heading**

\`### A subheading\`

_With the visual editor you can only use the third level heading._
`;
