import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { GuidelinePages } from '../../model/GuidelinePage';
import { updatePageHeader } from '../../model/GuidelinePagesEditor';
import { ContentDisplayAndEditor } from './ContentDisplayAndEditor';
import { TitleDisplayAndEditor } from './TitleDisplayAndEditor';

export type GuidelinePageEditorProps = {
  pageId: string;
  pages: GuidelinePages;
  setPages: (pages: GuidelinePages) => void;
};

/**
 * Displays and allows edits to a guideline page.
 *
 * Wraps the DisplayAndEditor controls and maintains
 * the model so sub-components update
 */
export function GuidelinePageEditor({
  pageId,
  pages,
  setPages,
}: GuidelinePageEditorProps): JSX.Element {
  const pageToEdit = pages.allPagesById.get(pageId);

  const updateTitleAndSubtitle = useCallback(
    (newTitle: string, newSubtitle: string) => {
      const updatedPages = updatePageHeader(
        pages,
        pageId,
        newTitle,
        newSubtitle
      );
      setPages(updatedPages);
    },
    [pageId, pages, setPages]
  );

  if (!pageToEdit) {
    const errorMessage = `Can't find page with ID: '${pageId}'`;
    return <Typography>{errorMessage}</Typography>;
  }

  return (
    <Stack spacing={4}>
      <TitleDisplayAndEditor
        title={pageToEdit.title}
        subtitle={pageToEdit.subheading ?? ''}
        setTitleAndSubtitle={updateTitleAndSubtitle}
      />
      <ContentDisplayAndEditor
        pageId={pageId}
        pages={pages}
        setPages={setPages}
      />
    </Stack>
  );
}
