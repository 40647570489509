import { createContext, useContext } from 'react';
import {
  EmptyGuidelinesProvider,
  GuidelinesProvider,
} from './GuidelinesProvider';

export const GuidelinesProviderContext = createContext<GuidelinesProvider>(
  EmptyGuidelinesProvider
);
export const useGuidelinesProviderContext = (): GuidelinesProvider =>
  useContext(GuidelinesProviderContext);
