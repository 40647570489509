import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { NavigationDrawer } from './NavigationDrawer';

export function AdminDashboard(): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Clinical Guidelines Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <NavigationDrawer />

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: '#f0f0f0' }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
