import { Device } from '@capacitor/device';
import { useContext, useEffect, useState } from 'react';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { GuidelinesAppBar } from '../GuidelinesAppBar';
import { ThemeAndTitleContext } from '../ThemeAndTitleProvider';
import { NavigationBreadcrumbs } from './NavigationBreadcrumbs';
import { Page } from './Page';
import { PageHeading } from './PageHeading';

export function Guideline(): JSX.Element {
  const { guidelines, pageId } = useGuidelinesProviderContext();
  const { setThemeFromName, removeSiteSpecifierFromName, appTitle } =
    useContext(ThemeAndTitleContext);
  const [devicePlatform, setDevicePlatform] = useState('');

  useEffect(() => {
    const populateDevicePlatform = async () => {
      const info = await Device.getInfo();

      setDevicePlatform(info.platform);
    };

    populateDevicePlatform();
  }, []);

  // If no page ID is passed then use the root page
  const pageIdToUse = pageId ?? guidelines.rootPage.id;

  const guidelinePage = guidelines.allPagesById.get(pageIdToUse);

  if (!guidelinePage) {
    return <div />;
  }

  setThemeFromName(guidelines.rootPage.title);
  const pageTitle = removeSiteSpecifierFromName(guidelinePage.title);

  const isOnMobileApp = devicePlatform !== 'web';

  return (
    <>
      <GuidelinesAppBar appTitle={appTitle} showNav={isOnMobileApp} />
      <NavigationBreadcrumbs />
      <PageHeading
        heading={pageTitle}
        subheading={guidelinePage.subheading}
        onClick={() => {}}
      />
      <Page guideline={guidelinePage} allPages={guidelines.allPagesById} />
    </>
  );
}
