import { createContext, useContext } from 'react';
import {
  EmptyGuidelineLoaderProvider,
  GuidelinesLoaderProvider,
} from './GuidelinesLoaderProvider';

export const GuidelinesLoaderProviderContext =
  createContext<GuidelinesLoaderProvider>(EmptyGuidelineLoaderProvider);
export const useGuidelinesLoaderProviderContext =
  (): GuidelinesLoaderProvider => useContext(GuidelinesLoaderProviderContext);
