import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { pageToMarkdown } from '../../guideline_parser/GuidelineToMarkdown';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { markdownToPages } from '../../guideline_parser/GuidelineParser';
import { MarkdownEditor } from './MarkdownEditor';

interface TextGuidelineEditorProps {
  editOnlySubPage?: boolean;
}
export function TextGuidelineEditor({
  editOnlySubPage,
}: TextGuidelineEditorProps): JSX.Element {
  const { guidelines, setGuidelines, pageId } = useGuidelinesProviderContext();

  const rootPageId = guidelines.rootPage.id;
  const subPageId = pageId ?? rootPageId;
  const pageIdToUse = editOnlySubPage ? subPageId : rootPageId;

  // TODO: We can't handle editing only the markdown for one page
  // and its subpages, so only editing the full page for now.

  const setMarkdown = useCallback(
    (newMarkdown: string) => {
      try {
        const newPages = markdownToPages(newMarkdown);
        setGuidelines(newPages);
      } catch (error) {
        // TODO: show the error box
      }
    },
    [setGuidelines]
  );

  const page = guidelines.allPagesById.get(pageIdToUse);
  if (!page) {
    const errorMessage = `Can't find page with ID: '${pageIdToUse}'`;
    return <Typography>{errorMessage}</Typography>;
  }

  try {
    const markdown = pageToMarkdown(page, guidelines.allPagesById);

    return <MarkdownEditor markdown={markdown} setMarkdown={setMarkdown} />;
  } catch {
    // if (fileHandle) {
    //   const file = await fileHandle.getFile();
    //   const fileContents = await file.text();
    //   return (
    //     <MarkdownEditor markdown={fileContents} setMarkdown={} />
    //   );
    // }
    return <Typography>Unsupported file format</Typography>;
  }
}
