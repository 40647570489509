import { buildUriTransformerFactory } from './UriTransformerFactory';
import { GuidelinesLoaderProvider } from './GuidelinesLoaderProvider';
import {
  loadGuidelineFromS3,
  loadGuidelinesIndexFromS3,
} from './s3GuidelinesLoader';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';

export function buildS3GuidelinesProvider(
  bucketName: string,
  rootPath: string
): GuidelinesLoaderProvider {
  // Regex to match 'public' for '/public/', '/public/', 'public/', and 'public'.
  const cleanPathRegex = /\/?(.*[^/])\/?/;
  const cleanRootPath = rootPath.replace(cleanPathRegex, '$1');
  const resourcesBucket = bucketName.replace('-json', '');
  const resourcesUrl = `https://s3.eu-west-1.amazonaws.com/${resourcesBucket}/${cleanRootPath}/`;
  const s3UriTransformer = buildUriTransformerFactory(resourcesUrl);
  let guidelinesIndexEntries: GuidelinesIndexEntry[] = [];

  const s3GuidelinesProvider: GuidelinesLoaderProvider = {
    loadGuidelinesIndex: async () => {
      if (guidelinesIndexEntries.length === 0) {
        guidelinesIndexEntries = await loadGuidelinesIndexFromS3(bucketName);
      }
      return guidelinesIndexEntries;
    },
    loadGuideline: async (guidelineKey) =>
      loadGuidelineFromS3(bucketName, cleanRootPath, guidelineKey),
    getUriTransformer: s3UriTransformer,
  };

  return s3GuidelinesProvider;
}
