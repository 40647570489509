import { createTheme } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';

declare module '@mui/material/styles' {
  interface Palette {
    frimley: Palette['primary'];
    wexham: Palette['primary'];
  }
  interface PaletteOptions {
    frimley: PaletteOptions['primary'];
    wexham: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    frimley: true;
    wexham: true;
  }
}

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#700331',
      contrastText: '#fff',
    },
    secondary: {
      main: '#005a96',
    },
    frimley: {
      main: '#2980B9',
      contrastText: '#fff',
    },
    wexham: {
      main: '#700331',
      contrastText: '#fff',
    },
  },
});

export const stagingTheme = createTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: '#005a96',
    },
    frimley: {
      main: '#2980B9',
      contrastText: '#fff',
    },
    wexham: {
      main: '#700331',
      contrastText: '#fff',
    },
  },
});

export const frimleyParkTheme = createTheme({
  palette: {
    primary: {
      main: '#2980B9',
    },
    secondary: {
      main: '#005a96',
    },
    frimley: {
      main: '#2980B9',
      contrastText: '#fff',
    },
    wexham: {
      main: '#700331',
      contrastText: '#fff',
    },
  },
});

export const wexhamParkTheme = createTheme({
  palette: {
    primary: {
      main: '#700331',
    },
    secondary: {
      main: '#005a96',
    },
    frimley: {
      main: '#2980B9',
      contrastText: '#fff',
    },
    wexham: {
      main: '#700331',
      contrastText: '#fff',
    },
  },
});
