import { Button } from '@mui/material';
import { useCallback } from 'react';

export type ChooseLocalFolderProps = {
  setLocalRepositoryDirectoryHandle: (
    directoryHandle: FileSystemDirectoryHandle
  ) => void;
};

export function ChooseLocalFolder({
  setLocalRepositoryDirectoryHandle,
}: ChooseLocalFolderProps): JSX.Element {
  const handleOpenFolder = useCallback(async () => {
    try {
      const newDirectoryHandle = await window.showDirectoryPicker();
      setLocalRepositoryDirectoryHandle(newDirectoryHandle);
    } catch (error) {
      // Do nothing
    }
  }, [setLocalRepositoryDirectoryHandle]);

  return <Button onClick={handleOpenFolder}>Open Folder</Button>;
}
