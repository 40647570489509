import { Box, Typography, Button } from '@mui/material';

const dropZoneBoxStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderColor: 'darkgrey',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
};

export type InlineDropZoneBoxProps = {
  openFileDialog: () => void;
};
export function InlineDropZoneBox({
  openFileDialog,
}: InlineDropZoneBoxProps): JSX.Element {
  return (
    <Box sx={dropZoneBoxStyle}>
      <Typography>
        Drag and drop a word file to convert or
        <Button onClick={openFileDialog}>Choose File</Button>
      </Typography>
      <Typography>
        <em>(Only *.docx files will be accepted)</em>
      </Typography>
    </Box>
  );
}
