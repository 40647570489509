import { Alert, Collapse, Grid, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useState } from 'react';
import { TemplateGuidelineCard } from './TemplateGuidelineCard';
import { getGuidelineTemplates } from '../../services/template_guidelines_provider';
import { NewGuidelineDialog } from './NewGuidelineDialog';

export function NewGuidelineTemplates(): JSX.Element {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState('');

  const [isNewGuidelineDialogOpen, setIsNewGuidelineDialogOpen] =
    useState(false);
  const [selectedTemplateCard, setSelectedTemplateCard] = useState('');

  const templateGuidelines = getGuidelineTemplates();

  const openNewGuidelineDialog = useCallback((key: string): void => {
    setSelectedTemplateCard(key);
    setIsNewGuidelineDialogOpen(true);
  }, []);

  const closeNewGuidelineDialog = useCallback((): void => {
    setIsNewGuidelineDialogOpen(false);
  }, []);

  const createNewGuideline = useCallback(
    async (
      title: string,
      subtitle: string | undefined,
      templateGuidelineName: string
    ): Promise<void> => {
      const haveSubtitle = subtitle !== undefined && subtitle.length > 0;
      const subtitleText = haveSubtitle ? ` [${subtitle}]` : '';
      const template = templateGuidelines.find(
        (value) => value.title === templateGuidelineName
      );
      const templateText = template ? template.template : '';
      const content = templateText.replaceAll('{title}', title);
      const markdown = `# ${title}${subtitleText}\n\n${content}`;

      try {
        await createMarkdownFile(title, markdown);
        setAlertText(`Created '${title}.md'`);
        setIsAlertOpen(true);
      } catch (error: any) {
        setErrorAlertText(error.message);
        setIsErrorAlertOpen(true);
      }
    },
    [templateGuidelines]
  );

  return (
    <>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setIsAlertOpen(false);
        }}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ marginBottom: 2 }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Collapse in={isErrorAlertOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsErrorAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ marginBottom: 2 }}
        >
          {errorAlertText}
        </Alert>
      </Collapse>
      <Grid container spacing={2} paddingBottom={4}>
        {templateGuidelines.map((templateGuideline) => (
          <Grid item>
            <TemplateGuidelineCard
              title={templateGuideline.title}
              description={templateGuideline.description}
              onClick={openNewGuidelineDialog}
            />
          </Grid>
        ))}
      </Grid>
      <NewGuidelineDialog
        open={isNewGuidelineDialogOpen}
        templateGuidelineName={selectedTemplateCard}
        handleClose={closeNewGuidelineDialog}
        handleSubmit={createNewGuideline}
      />
    </>
  );
}

async function createMarkdownFile(title: string, content: string) {
  const dirHandle = await window.showDirectoryPicker();
  const fileName = `${title}.md`;
  const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
  const writableStream = await fileHandle.createWritable();
  await writableStream.write(content);
  writableStream.close();
}
