// eslint-disable-next-line import/no-cycle
import { Section } from './Section';
import { GuidelinePage } from '../../model/GuidelinePage';

export type PageProps = {
  guideline: GuidelinePage;
  allPages: Map<string, GuidelinePage>;
};

export function Page({ guideline, allPages }: PageProps): JSX.Element {
  return (
    <div style={{ background: 'whitesmoke' }}>
      {guideline.sections.map((section) => (
        <Section section={section} key={section.id} allPages={allPages} />
      ))}
    </div>
  );
}
