import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ImageIcon from '@mui/icons-material/Image';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
// import GridOnIcon from '@mui/icons-material/GridOn';
import { MenuCreateIcon, SpecialCharactersIcon } from './EditorIcons';

interface MarkdownFormattingBarProps {
  boldHandler: () => void;
  italicHandler: () => void;
  linkHandler: () => void;
  imageHandler: () => void;
  subPageHandler: () => void;
  undoHandler: () => void;
  redoHandler: () => void;
  undoEnabled: boolean;
  redoEnabled: boolean;
  specialCharactersHandler: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

export function MarkdownFormattingBar({
  boldHandler,
  italicHandler,
  linkHandler,
  imageHandler,
  subPageHandler,
  undoHandler,
  redoHandler,
  undoEnabled,
  redoEnabled,
  specialCharactersHandler,
}: MarkdownFormattingBarProps) {
  return (
    <ButtonGroup>
      <IconButton onClick={undoHandler} disabled={!undoEnabled}>
        <UndoIcon />
      </IconButton>
      <IconButton onClick={redoHandler} disabled={!redoEnabled}>
        <RedoIcon />
      </IconButton>
      <IconButton onClick={boldHandler}>
        <FormatBoldIcon />
      </IconButton>
      <IconButton onClick={italicHandler}>
        <FormatItalicIcon />
      </IconButton>
      {/* <IconButton>
        <FormatListBulletedIcon />
      </IconButton>
      <IconButton>
        <FormatListNumberedIcon />
      </IconButton> */}
      <Tooltip title="Insert Link">
        <IconButton onClick={linkHandler}>
          <InsertLinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Insert Image">
        <IconButton onClick={imageHandler}>
          <ImageIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Special Characters">
        <IconButton onClick={specialCharactersHandler}>
          <SpecialCharactersIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Insert Sub Page">
        <IconButton onClick={subPageHandler}>
          <MenuCreateIcon />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
}
