import { SvgIcon } from '@mui/material';

export function MenuCreateIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(-1,0,0,1,24,0)">
        <path d="M3,18L16,18L16,16L3,16L3,18ZM3,13L13,13L13,11L3,11L3,13ZM3,6L3,8L16,8L16,6L3,6ZM21,15.59L17.42,12L21,8.41L19.59,7L14.59,12L19.59,17L21,15.59Z" />
      </g>{' '}
    </SvgIcon>
  );
}

export function SpecialCharactersIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(1.61422,0,0,1.61422,4.02329,-4.69135)">
        <path d="M10.517,10.742C9.934,11.002 9.238,11.132 8.43,11.132C7.442,11.132 6.673,10.854 6.122,10.298C5.572,9.742 5.296,8.966 5.296,7.971C5.296,6.956 5.574,6.176 6.129,5.629C6.683,5.082 7.475,4.809 8.503,4.809C9.157,4.809 9.809,4.886 10.46,5.041L10.46,6.022C9.725,5.764 9.109,5.635 8.613,5.635C7.978,5.635 7.488,5.836 7.144,6.239C6.799,6.642 6.627,7.214 6.627,7.954C6.627,8.681 6.812,9.248 7.182,9.655C7.553,10.062 8.067,10.266 8.727,10.266C9.318,10.266 9.915,10.116 10.517,9.818L10.517,10.742ZM3.986,6.864C3.663,6.864 3.385,6.745 3.15,6.508C2.915,6.27 2.798,5.988 2.798,5.659C2.798,5.328 2.916,5.045 3.152,4.809C3.388,4.573 3.672,4.455 4.003,4.455C4.333,4.455 4.617,4.572 4.853,4.807C5.089,5.042 5.207,5.324 5.207,5.655C5.207,5.994 5.089,6.281 4.853,6.514C4.617,6.747 4.328,6.864 3.986,6.864ZM3.994,6.262C4.165,6.262 4.31,6.203 4.428,6.087C4.546,5.97 4.605,5.828 4.605,5.659C4.605,5.494 4.546,5.352 4.428,5.234C4.31,5.116 4.168,5.057 4.003,5.057C3.837,5.057 3.695,5.116 3.577,5.234C3.459,5.352 3.4,5.494 3.4,5.659C3.4,5.825 3.459,5.967 3.575,6.085C3.692,6.203 3.832,6.262 3.994,6.262Z" />
      </g>
      <g transform="matrix(1.51279,0,0,1.51279,-1.2956,4.06491)">
        <path d="M2.859,8.036C2.859,7.428 2.95,6.918 3.132,6.506C3.267,6.202 3.453,5.929 3.687,5.688C3.922,5.446 4.179,5.267 4.458,5.151C4.83,4.993 5.258,4.915 5.744,4.915C6.623,4.915 7.326,5.187 7.854,5.733C8.381,6.278 8.645,7.036 8.645,8.007C8.645,8.97 8.383,9.724 7.86,10.268C7.336,10.811 6.637,11.083 5.76,11.083C4.873,11.083 4.168,10.813 3.644,10.272C3.121,9.73 2.859,8.985 2.859,8.036ZM4.1,7.995C4.1,8.671 4.256,9.183 4.568,9.531C4.88,9.88 5.276,10.054 5.756,10.054C6.236,10.054 6.63,9.881 6.938,9.535C7.246,9.189 7.4,8.671 7.4,7.979C7.4,7.295 7.25,6.785 6.951,6.449C6.651,6.112 6.253,5.944 5.756,5.944C5.26,5.944 4.86,6.114 4.556,6.455C4.252,6.795 4.1,7.309 4.1,7.995Z" />
        <g transform="matrix(1,0,0,1,-0.543953,0)">
          <path d="M9.683,12.186L9.683,11.503C9.829,11.337 9.981,11.191 10.139,11.063L10.464,10.795C11.053,10.312 11.347,9.891 11.347,9.533C11.347,9.197 11.14,9.029 10.725,9.029C10.456,9.029 10.14,9.113 9.776,9.281L9.776,8.671C10.181,8.546 10.566,8.483 10.932,8.483C11.353,8.483 11.687,8.574 11.935,8.754C12.183,8.934 12.307,9.178 12.307,9.484C12.307,9.856 12.046,10.245 11.522,10.652L11.237,10.876C10.92,11.12 10.734,11.329 10.68,11.503L12.299,11.503L12.299,12.186L9.683,12.186Z" />
        </g>
      </g>
    </SvgIcon>
  );
}
