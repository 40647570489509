import { Typography, Button } from '@mui/material';
import { ActionStepBox } from './ActionStepBox';
import { ChooseLocalFolder } from '../routes/ChooseLocalFolder';
import { Contents } from '../Contents';
import { useAuthoringContext } from './AuthoringDashboard';

export function PreviewGuidelines(): JSX.Element {
  const {
    localRepositoryDirectoryHandle,
    setLocalRepositoryDirectoryHandle,
    isGuidelinesProviderReady,
  } = useAuthoringContext();
  const haveGuidelinesProvider = localRepositoryDirectoryHandle !== undefined;
  return (
    <>
      {!haveGuidelinesProvider && (
        <ActionStepBox
          headingText="Where are the guidelines on your computer?"
          subHeading={
            <>
              <Typography component="span">
                You need to have downloaded them first.
              </Typography>
              <Button size="small">Learn More</Button>
            </>
          }
          contents={
            <ChooseLocalFolder
              setLocalRepositoryDirectoryHandle={
                setLocalRepositoryDirectoryHandle
              }
            />
          }
        />
      )}
      {haveGuidelinesProvider && isGuidelinesProviderReady && <Contents />}
    </>
  );
}
