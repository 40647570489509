import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import CloseIcon from '@mui/icons-material/Close';
import { formattingHelpMarkdown } from './FormattingHelp';

interface FormattingHelpDialogProps {
  open: boolean;
  handleClose: () => void;
}

export function FormattingHelpDialog({
  open,
  handleClose,
}: FormattingHelpDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <ReactMarkdown>{formattingHelpMarkdown}</ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleClose} color="primary">
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
