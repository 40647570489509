import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';
import { ResourcePathAndUrl } from './FileSystemGuidelinesLoader';
import { GuidelinesAndSource } from './GuidelinesProvider';

export interface GuidelinesLoaderProvider {
  loadGuidelinesIndex: () => Promise<GuidelinesIndexEntry[]>;
  loadGuideline: (guidelineKey: string) => Promise<GuidelinesAndSource>;
  getUriTransformer: (sourcePath: string) => (uri: string) => string;
  resourcesUrlsBySourcePath?: Map<string, ResourcePathAndUrl[]>;
}

export const EmptyGuidelineLoaderProvider: GuidelinesLoaderProvider = {
  loadGuidelinesIndex: (): Promise<GuidelinesIndexEntry[]> =>
    new Promise((resolve, reject) => {
      reject(new Error('No GuidelineProvider to load index.'));
    }),
  loadGuideline: (guidelineKey: string): Promise<GuidelinesAndSource> =>
    new Promise((resolve, reject) => {
      reject(
        new Error(`No GuidelineProvider to load guideline: ${guidelineKey}`)
      );
    }),
  getUriTransformer: (): ((uri: string) => string) => (uri: string) => uri,
};
