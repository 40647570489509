import { ThemeProvider } from '@mui/material';
import { createContext, useCallback, useState } from 'react';
import {
  frimleyParkTheme,
  mainTheme,
  stagingTheme,
  wexhamParkTheme,
} from '../Themes';

export type BrandingType = 'normal' | 'staging' | 'frimley' | 'wexham';
export type EnvironmentType = 'normal' | 'staging';

interface TitlesAndSetters {
  appTitle: string;
  setAppTitle: (appTitle: string) => void;
  currentBranding: BrandingType;
  setCurrentBranding: (branding: BrandingType) => void;
  environment: EnvironmentType;
  setEnvironment: (environment: EnvironmentType) => void;
  setThemeFromName: (guidelineTitle: string) => void;
  removeSiteSpecifierFromName: (guidelineTitle: string) => string;
}

const emptyTitleAndSetter: TitlesAndSetters = {
  appTitle: 'Montreux Knowledge',
  setAppTitle: () => {},
  currentBranding: 'normal',
  setCurrentBranding: () => {},
  environment: 'normal',
  setEnvironment: () => {},
  setThemeFromName: () => {},
  removeSiteSpecifierFromName: (guidelineTitle) => guidelineTitle,
};

export const ThemeAndTitleContext =
  createContext<TitlesAndSetters>(emptyTitleAndSetter);

export function ThemeAndTitleProvider({
  children,
}: {
  children: any;
}): JSX.Element {
  const [appTitle, setAppTitle] = useState('');
  const [currentBranding, setCurrentBranding] =
    useState<BrandingType>('normal');
  const [environment, setEnvironment] = useState<EnvironmentType>('normal');

  let currentTheme = mainTheme;
  switch (currentBranding) {
    case 'staging':
      currentTheme = stagingTheme;
      break;
    case 'frimley':
      currentTheme = frimleyParkTheme;
      break;
    case 'wexham':
      currentTheme = wexhamParkTheme;
      break;
    case 'normal':
    default:
      break;
  }

  const setThemeFromName = useCallback(
    (guidelineName: string) => {
      const titleIncludesFph = doesTitleIncludeFph(guidelineName);
      const titleIncludesWph = doesTitleIncludeWph(guidelineName);

      const isStaging = environment === 'staging';

      let brandingToUse: BrandingType = isStaging ? 'staging' : 'normal';
      brandingToUse =
        titleIncludesFph && !titleIncludesWph ? 'frimley' : brandingToUse;
      brandingToUse =
        titleIncludesWph && !titleIncludesFph ? 'wexham' : brandingToUse;

      const stagingPrefix = isStaging ? 'FOR REVIEW ONLY—' : '';

      let newAppTitle = '';
      switch (brandingToUse) {
        case 'frimley':
          newAppTitle = isStaging ? 'Frimley Park' : 'Frimley Park Only';
          break;
        case 'wexham':
          newAppTitle = isStaging ? 'Wexham Park' : 'Wexham Park Only';
          break;
        case 'normal':
        default:
          newAppTitle = 'Montreux Knowledge';
          break;
      }

      setAppTitle(`${stagingPrefix}${newAppTitle}`);
      setCurrentBranding(brandingToUse);
    },
    [environment]
  );

  return (
    <ThemeAndTitleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        appTitle,
        setAppTitle,
        currentBranding,
        setCurrentBranding,
        environment,
        setEnvironment,
        setThemeFromName,
        removeSiteSpecifierFromName,
      }}
    >
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeAndTitleContext.Provider>
  );
}

export function doesTitleIncludeWph(guidelineName: string) {
  const wexhamParkSpecificMatch = guidelineName.match(/(\W*wph\W*)/gim);
  const titleIncludesWph = Boolean(wexhamParkSpecificMatch);
  return titleIncludesWph;
}

export function doesTitleIncludeFph(guidelineName: string) {
  const frimleyParkSpecificMatch = guidelineName.match(/(\W*fph\W*)/gim);
  const titleIncludesFph = Boolean(frimleyParkSpecificMatch);
  return titleIncludesFph;
}

export function removeSiteSpecifierFromName(guidelineName: string): string {
  const titleIncludesFph = doesTitleIncludeFph(guidelineName);
  const titleIncludesWph = doesTitleIncludeWph(guidelineName);

  const nameWithoutSpecifiers = guidelineName
    .replace(/([ -]*\Wfph\W)/gim, '')
    .replace(/([ -]*\Wwph\W)/gim, '');

  const isSiteSpecific =
    (titleIncludesFph && !titleIncludesWph) ||
    (!titleIncludesFph && titleIncludesWph);

  return isSiteSpecific ? nameWithoutSpecifiers : guidelineName;
}
