import { buildFileSystemUriTransformerFactory } from './UriTransformerFactory';
import { GuidelinesLoaderProvider } from './GuidelinesLoaderProvider';
import {
  loadGuidelineFromFileSystem,
  loadGuidelineMarkdownFromFileSystem,
  loadGuidelinesIndexFromFileSystem,
} from './FileSystemGuidelinesLoader';

export interface FileSystemGuidelinesLoaderProvider
  extends GuidelinesLoaderProvider {
  loadGuidelineMarkdown: (guidelineKey: string) => Promise<string>;
  getFileHandle: (guidelineKey: string) => Promise<FileSystemFileHandle>;
}

export async function buildFileSystemGuidelinesProvider(
  rootPath: FileSystemDirectoryHandle
): Promise<FileSystemGuidelinesLoaderProvider> {
  const guidelinesIndexPromise = loadGuidelinesIndexFromFileSystem(rootPath);
  const { getUriTransformer, resourcesUrlsBySourcePath } =
    await buildFileSystemUriTransformerFactory(rootPath);

  return {
    loadGuidelinesIndex: () => guidelinesIndexPromise,
    loadGuideline: async (guidelineKey: string) => {
      const guidelinesIndex = await guidelinesIndexPromise;

      const guidelineIndexToOpen = guidelinesIndex.find(
        (guidelineIndexEntry) => guidelineIndexEntry.key === guidelineKey
      );

      return new Promise((resolve, reject) => {
        if (guidelineIndexToOpen && guidelineIndexToOpen.fileHandle) {
          resolve(loadGuidelineFromFileSystem(guidelineIndexToOpen));
        }

        reject(new Error(`Can't open file for key ${guidelineKey}`));
      });
    },
    loadGuidelineMarkdown: async (guidelineKey: string) => {
      const guidelinesIndex = await guidelinesIndexPromise;

      const guidelineIndexToOpen = guidelinesIndex.find(
        (guidelineIndexEntry) => guidelineIndexEntry.key === guidelineKey
      );

      return new Promise((resolve, reject) => {
        if (guidelineIndexToOpen) {
          const guidelineFileHandle = guidelineIndexToOpen.fileHandle;
          if (guidelineFileHandle) {
            resolve(loadGuidelineMarkdownFromFileSystem(guidelineFileHandle));
          }
        }

        reject(new Error(`Can't open file for key ${guidelineKey}`));
      });
    },

    getUriTransformer,
    getFileHandle: async (guidelineKey: string) => {
      const guidelinesIndex = await guidelinesIndexPromise;

      const guidelineIndexToOpen = guidelinesIndex.find(
        (guidelineIndexEntry) => guidelineIndexEntry.key === guidelineKey
      );

      return new Promise((resolve, reject) => {
        if (guidelineIndexToOpen) {
          const guidelineFileHandle = guidelineIndexToOpen.fileHandle;
          if (guidelineFileHandle) {
            resolve(guidelineFileHandle);
          }
        }

        reject(new Error(`Can't open file for key ${guidelineKey}`));
      });
    },
    resourcesUrlsBySourcePath,
  };
}
