import {
  GuidelinePages,
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from '../model/GuidelinePage';
import { getHeaderDetails } from './HeaderParser';

export function markdownToPages(markdownText: string): GuidelinePages {
  const allPages = markdownToPage(markdownText);
  const pagesById = buildGuidelinesMap(allPages);
  return { rootPage: allPages[0], allPagesById: pagesById };
}
export function markdownToPage(markdownText: string): GuidelinePage[] {
  if (!markdownText) {
    return [];
  }

  let currentPage: GuidelinePage = {
    id: '',
    title: '',
    subheading: '',
    sections: [{ content: '', id: '0' }],
  };

  let isInSubPage = false;
  const pagesStack = [currentPage];
  const allPages = [currentPage];

  const allLines = markdownText.split(/\n/);

  allLines.forEach((line, index) => {
    try {
      const isStartingSubMenu = line.startsWith('>>');
      const isEndingSubMenu = line.startsWith('<<');
      const isHeadingOne = line.startsWith('#') && !line.startsWith('##');
      const isHeadingTwo = line.startsWith('##') && !line.startsWith('###');

      if (isStartingSubMenu) {
        const headerDetails = getHeaderDetails(line);

        // The last section of the last page
        const pageSections = pagesStack[pagesStack.length - 1].sections;
        const lastSection = pageSections[pageSections.length - 1];

        // New page ID
        const pageId = `${currentPage.id}-${lastSection.id}/${headerDetails.title}`;

        // Build the submenu definition
        const subMenuItem: MenuItem = {
          title: headerDetails.title,
          subtitle: headerDetails.subtitleText,
          color: headerDetails.colorHexValue,
          targetType: headerDetails.targetType ?? 'page',
          target: headerDetails.target ?? pageId,
        };

        // Add the submenu to the last section of the last page

        if (lastSection.menuItems !== undefined) {
          pageSections[pageSections.length - 1].menuItems?.push(subMenuItem);
        } else {
          pageSections[pageSections.length - 1].menuItems = [subMenuItem];
        }

        // If the submenu is to a new page then build the outline of it and add it to the stack
        const isNewSubPage = subMenuItem.targetType === 'page';
        if (isNewSubPage) {
          const newPage: GuidelinePage = {
            id: pageId,
            title: headerDetails.title,
            subheading: headerDetails.subtitleText,
            sections: [{ content: '', id: '0' }],
          };
          pagesStack.push(newPage);
          allPages.push(newPage);
          currentPage = pagesStack[pagesStack.length - 1];
        }
        isInSubPage = isNewSubPage;
      } else if (isEndingSubMenu) {
        if (isInSubPage) {
          pagesStack.pop();
          currentPage = pagesStack[pagesStack.length - 1];
        }
        isInSubPage = pagesStack.length > 1;
      } else if (isHeadingOne) {
        const headerDetails = getHeaderDetails(line);
        if (headerDetails.title.trim() !== '') {
          currentPage.title = headerDetails.title;
        }
        if (headerDetails.subtitleText?.trim() !== '') {
          currentPage.subheading = headerDetails.subtitleText;
        } else {
          currentPage.subheading = undefined;
        }

        const isFirstPage = pagesStack.length === 1;
        if (isFirstPage) {
          currentPage.id = currentPage.title;
        }
      } else if (isHeadingTwo) {
        const headerDetails = getHeaderDetails(line);
        const newSection: GuidelineSection = { content: '' };

        newSection.color = headerDetails.colorHexValue;
        newSection.footer = headerDetails.subtitleText;
        if (headerDetails.title.trim() !== '') {
          newSection.header = headerDetails.title;
        }
        // We can worry about Microguide when we do this for Medway
        // when (headerDetails.target) {
        //     is MenuItemTarget.Microguide -> newSection = headerDetails.target.guidelinesPageSection
        // }

        // Remove current section if it is empty
        const currentSection =
          currentPage.sections[currentPage.sections.length - 1];
        const hasContent = currentSection.content.trim().length > 0;
        const hasSubMenus =
          currentSection.menuItems && currentSection.menuItems.length > 0;
        if (!(hasContent || hasSubMenus)) {
          currentPage.sections.pop();
        }

        newSection.id = currentPage.sections.length.toString();
        currentPage.sections.push(newSection);
      } else {
        const lastSection =
          currentPage.sections[currentPage.sections.length - 1];

        if (!lastSection.content) {
          lastSection.content = line;
        } else {
          lastSection.content += line;
        }
        if (lastSection.content !== '') {
          lastSection.content += '\n';
        }
      }
    } catch (error: any) {
      throw new Error(`Error parsing line ${index}: ${error}`);
    }
  });

  // const notValidInput = allPages.length === 1 && currentPage.id === '';
  // if (notValidInput) {
  //   return [];
  // }

  return allPages;
}

export function buildGuidelinesMap(
  guidelines: GuidelinePage[]
): Map<string, GuidelinePage> {
  const guidelinesMap = new Map<string, GuidelinePage>();
  guidelines.forEach((guidelinePage) => {
    guidelinesMap.set(guidelinePage.id, guidelinePage);
  });

  return guidelinesMap;
}
