import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Contents } from './components/Contents';
import { Guideline } from './components/guideline_display/Guideline';
import { AdminDashboard } from './components/admin/AdminDashboard';
import { NewGuidelineTemplates } from './components/admin/NewGuidelineTemplates';
import { GuidelinesLoaderProviderContext } from './services/GuidelinesLoaderProviderContext';
import { buildS3GuidelinesProvider } from './services/S3GuidelinesProvider';
import { Guidelines } from './components/Guidelines';
import { ThemeAndTitleProvider } from './components/ThemeAndTitleProvider';
import { WordImporter } from './components/authoring/WordImporter';
import { AuthoringDashboard } from './components/authoring/AuthoringDashboard';
import { PreviewGuidelines } from './components/authoring/PreviewGuidelines';
import { EditableGuideline } from './components/authoring/EditableGuideline';
import { GuidelineLoader } from './components/GuidelineLoader';
import { ColourGrid } from './components/routes/ColourGrid';
import { ScrollToTop } from './components/ScrollToTop';

export function App(): JSX.Element {
  return (
    <ThemeAndTitleProvider>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      'com.montreuxknowledge.main-json',
                      'public'
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route path="authoring" element={<AuthoringDashboard />}>
                <Route index element={<PreviewGuidelines />} />
                <Route path=":encodedUrl" element={<EditableGuideline />} />
                <Route
                  path="newguideline"
                  element={<NewGuidelineTemplates />}
                />
                <Route path="importworddocument" element={<WordImporter />} />
                <Route path="colourgrid" element={<ColourGrid />} />
              </Route>
              <Route
                path="authoringhelp"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      'uk.co.montreux.authoringhelp-json',
                      'public'
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route path="admin" element={<AdminDashboard />}>
                <Route
                  path="reports"
                  element={
                    <Typography variant="h6" noWrap component="div">
                      Reports
                    </Typography>
                  }
                />
                <Route
                  index
                  element={
                    <Typography variant="h6" noWrap component="div">
                      Admin Dashboard
                    </Typography>
                  }
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </ThemeAndTitleProvider>
  );
}
