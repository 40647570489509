import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getGuidelineTemplates } from '../../services/template_guidelines_provider';
// import { PageHeading } from '../guideline_display/PageHeading';

const templateGuidelines = getGuidelineTemplates();

type NewGuidelineDialogProps = {
  open: boolean;
  templateGuidelineName: string;
  handleClose: () => void;
  handleSubmit: (
    title: string,
    subtitle: string | undefined,
    templateName: string
  ) => void;
};

export function NewGuidelineDialog({
  open,
  templateGuidelineName,
  handleClose,
  handleSubmit,
}: NewGuidelineDialogProps): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [workingTitle, setWorkingTitle] = useState('');
  const [workingSubtitle, setWorkingSubtitle] = useState('');

  const isCreateDisabled = workingTitle.length === 0;

  const handleGuidelineTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setWorkingTitle(event.target.value);
    },
    []
  );

  const handleGuidelineSubtitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setWorkingSubtitle(event.target.value);
    },
    []
  );

  const handleFormSubmit = useCallback((): void => {
    handleSubmit(workingTitle, workingSubtitle, templateGuidelineName);
    setWorkingTitle('');
    setWorkingSubtitle('');
    handleClose();
  }, [
    handleClose,
    handleSubmit,
    templateGuidelineName,
    workingSubtitle,
    workingTitle,
  ]);

  const guidelineTemplate = templateGuidelines.find(
    (template) => template.title === templateGuidelineName
  );
  let dynamicHelpText = guidelineTemplate?.helpMarkdown ?? '';
  dynamicHelpText = dynamicHelpText.replaceAll('{title}', workingTitle);
  dynamicHelpText = dynamicHelpText.replaceAll('{subtitle}', workingSubtitle);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="responsive-dialog-title">New Guideline</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={workingTitle}
          onChange={handleGuidelineTitleChange}
        />
        <TextField
          margin="dense"
          id="subtitle"
          label="Subtitle (optional)"
          type="text"
          fullWidth
          variant="standard"
          value={workingSubtitle}
          onChange={handleGuidelineSubtitleChange}
        />
        {/* <div style={{ paddingTop: 2, marginBottom: 2 }}>
          <PageHeading heading={workingTitle} subheading={workingSubtitle} />
        </div> */}
        <ReactMarkdown>{dynamicHelpText}</ReactMarkdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleFormSubmit}
          color="primary"
          disabled={isCreateDisabled}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
