interface Replacement {
  search: string;
  replace: string;
}

export function guidelinePathToUrlParam(guidelinePath: string): string {
  const replacements: Replacement[] = [
    { search: '/', replace: '-' },
    { search: '&', replace: 'and' },
    { search: ' - ', replace: '-' },
    { search: ' ', replace: '_' },
    { search: ',', replace: '' },
  ];
  let afterReplace = guidelinePath;
  replacements.forEach(({ search, replace }) => {
    afterReplace = afterReplace.replaceAll(search, replace);
  });

  const safeUrl = encodeURIComponent(afterReplace);

  return safeUrl;
}
