import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

export function Guidelines(): JSX.Element {
  return (
    <Container maxWidth="md" sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Outlet />
    </Container>
  );
}
