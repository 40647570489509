import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  IconButton,
  Button,
} from '@mui/material';
import { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { GuidelineSection } from '../../model/GuidelinePage';
import { MarkdownTextField } from './MarkdownTextField';
import { useUndoableState } from '../../services/useUndoableState';

type SectionEditorFormData = {
  title: string;
  markdown: string;
};

type SectionEditorProps = {
  open: boolean;
  handleClose: () => void;
  updateSection: (newSection: GuidelineSection) => void;
  section: GuidelineSection;
};

export function SectionEditorDialog({
  open,
  handleClose,
  section,
  updateSection,
}: SectionEditorProps): JSX.Element {
  const { handleSubmit, control, reset } = useForm<SectionEditorFormData>();

  const onSubmit = useCallback(
    (data: SectionEditorFormData): void => {
      const newSection = { ...section };
      newSection.header = data.title;
      newSection.content = data.markdown;

      updateSection(newSection);
    },
    [section, updateSection]
  );

  const {
    state: workingText,
    setState: setWorkingText,
    resetState: resetWorkingText,
    index: textStateIndex,
    lastIndex: textStateLastIndex,
    goBack: undoTextChange,
    goForward: redoTextChange,
  } = useUndoableState(section.content);
  const canUndo = textStateIndex > 0;
  const canRedo = textStateIndex < textStateLastIndex;

  const onClose = useCallback(() => {
    reset();
    resetWorkingText(section.content);
    handleClose();
  }, [handleClose, reset, resetWorkingText, section.content]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      scroll="paper"
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset()}>
        <DialogContent>
          <Stack>
            <Controller
              name="title"
              control={control}
              defaultValue={section.header}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Heading (Optional)"
                  value={value}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="markdown"
              control={control}
              defaultValue={section.content}
              render={({ field: { onChange } }) => (
                <MarkdownTextField
                  label="Content"
                  value={workingText}
                  margin="normal"
                  variant="outlined"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={12}
                  onChange={(event) => {
                    setWorkingText(event.target.value);
                    onChange(event);
                  }}
                  onUndoPress={() => {
                    undoTextChange();
                  }}
                  onRedoPress={() => {
                    redoTextChange();
                  }}
                  undoEnabled={canUndo}
                  redoEnabled={canRedo}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <IconButton type="reset" onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
          <Button
            type="submit"
            onClick={handleClose}
            color="primary"
            variant="contained"
            disableElevation
          >
            OK
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
