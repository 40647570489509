import {
  GuidelinePages,
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from './GuidelinePage';

export function updateMenuItems(
  parentGuidelines: GuidelinePages,
  pageId: string,
  sectionId: string,
  menuItems: MenuItem[]
) {
  const pageToEdit = parentGuidelines.allPagesById.get(pageId);
  if (!pageToEdit) {
    throw new Error(`Can't find page with id: ${pageId}`);
  }

  // Rebuild the section array by mapping the original section
  // array and replacing the one with the matching ID with updatedSection
  const newSections = pageToEdit.sections.map((section) => {
    if (sectionId === section.id) {
      const updatedSection: GuidelineSection = { ...section };
      updatedSection.menuItems = menuItems;
      return updatedSection;
    }
    return section;
  });

  // Rebuild the page with the updated sections
  const updatedPage: GuidelinePage = { ...pageToEdit };
  updatedPage.sections = newSections;

  const updatedPages = updatePage(parentGuidelines, pageId, updatedPage);

  return updatedPages;
}

/**
 * Returns a copy of parentGuidelines without the GuidelineSection
 * with the passed sectionId in the page with pageId.
 *
 * @param parentGuidelines The parent GuidelinePages
 * @param pageId The ID of the page containing the section
 * @param sectionId The ID of the section to delete
 * @returns A new GuidelinePages
 * @throws If the pageId isn't found
 */
export function deleteSection(
  parentGuidelines: GuidelinePages,
  pageId: string,
  sectionId: string
): GuidelinePages {
  const pageToEdit = parentGuidelines.allPagesById.get(pageId);
  if (!pageToEdit) {
    throw new Error(`Can't find page with id: ${pageId}`);
  }

  // Build a new section array without the section to delete
  const newSections = pageToEdit.sections.filter(
    (section) => section.id !== sectionId
  );

  // Rebuild the page with the updated sections
  const updatedPage: GuidelinePage = { ...pageToEdit };
  updatedPage.sections = newSections;

  const updatedPages = updatePage(parentGuidelines, pageId, updatedPage);

  return updatedPages;
}

/**
 * Returns a copy of parentGuidelines with the passed GuidelineSection replacing
 * the original with the same ID in the page with pageId.
 *
 * @param parentGuidelines The parent GuidelinePages
 * @param pageId The ID of the page containing the section
 * @param updatedSection The new GuidelineSection to replace the one with same ID
 * @returns A new GuidelinePages
 * @throws If the pageId isn't found or updatedSection.ID is undefined
 */
export function updateSection(
  parentGuidelines: GuidelinePages,
  pageId: string,
  updatedSection: GuidelineSection
): GuidelinePages {
  const pageToEdit = parentGuidelines.allPagesById.get(pageId);
  if (!pageToEdit) {
    throw new Error(`Can't find page with id: ${pageId}`);
  }
  if (!updatedSection.id) {
    throw new Error(`Can't update a GuidelineSection without an ID`);
  }
  // Rebuild the section array by mapping the original section
  // array and replacing the one with the matching ID with updatedSection
  const newSections = pageToEdit.sections.map((section) => {
    if (updatedSection.id === section.id) {
      return updatedSection;
    }
    return section;
  });

  // Rebuild the page with the updated sections
  const updatedPage: GuidelinePage = { ...pageToEdit };
  updatedPage.sections = newSections;

  const updatedPages = updatePage(parentGuidelines, pageId, updatedPage);

  return updatedPages;
}

/**
 * Returns a copy of parentGuidelines with the passed GuidelinePage replacing
 * the original with the same ID.
 *
 * @param parentGuidelines The parent GuidelinePages
 * @param pageId The ID of the page to replace
 * @param updatedPage The new GuidelinePage to replace the one with same ID
 * @returns A new GuidelinePages
 */
function updatePage(
  parentGuidelines: GuidelinePages,
  pageId: string,
  updatedPage: GuidelinePage
): GuidelinePages {
  // Rebuild 'allPagesById' with the updated page
  const newAllPagesById = new Map(parentGuidelines.allPagesById);
  newAllPagesById.set(pageId, updatedPage);

  // See if we need to update the root page
  const isUpdatingRootPage = parentGuidelines.rootPage.id === updatedPage.id;
  const newRootPage = isUpdatingRootPage
    ? updatedPage
    : parentGuidelines.rootPage;

  // Build a new GuidelinePages with the updated map and root page
  const updatedPages: GuidelinePages = {
    rootPage: newRootPage,
    allPagesById: newAllPagesById,
  };
  return updatedPages;
}

/**
 * Returns a copy of parentGuidelines with the page title and subheading
 * changed for the page with the passed pageId
 *
 * @param parentGuidelines The parent GuidelinePages
 * @param pageId The ID of the page to update
 * @param newTitle New heading
 * @param newSubheading New subheading
 * @returns A new GuidelinePages
 * @throws If the pageId isn't found or updatedSection.ID is undefined
 */
export function updatePageHeader(
  parentGuidelines: GuidelinePages,
  pageId: string,
  newTitle: string,
  newSubheading: string
): GuidelinePages {
  const pageToEdit = parentGuidelines.allPagesById.get(pageId);
  if (!pageToEdit) {
    throw new Error(`Can't find page with id: ${pageId}`);
  }

  // Rebuild the page with the updated heading
  const updatedPage: GuidelinePage = { ...pageToEdit };
  updatedPage.title = newTitle;
  updatedPage.subheading =
    newSubheading.trim().length > 0 ? newSubheading : undefined;

  const updatedPages = updatePage(parentGuidelines, pageId, updatedPage);

  return updatedPages;
}
