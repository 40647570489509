import { useEffect, useState, useRef } from 'react';
import { pagesToMarkdown } from '../../guideline_parser/GuidelineToMarkdown';
import { GuidelinePages } from '../../model/GuidelinePage';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';

interface AutoSaveUpdatedGuidelineProps {
  setIsSaving: (isSaving: boolean) => void;
  setSaveError: (error: Error) => void;
}

export function AutoSaveUpdatedGuideline({
  setIsSaving,
  setSaveError,
}: AutoSaveUpdatedGuidelineProps) {
  const { guidelines, fileHandle } = useGuidelinesProviderContext();

  const canHandleGuideline = useRef(false);

  useEffect(() => {
    try {
      pagesToMarkdown(guidelines);
      canHandleGuideline.current = true;
    } catch (error: any) {
      setSaveError(error);
    }
  }, [guidelines, setSaveError]);

  const newMarkdown = safePagesToMarkdown(guidelines);

  const [currentMarkdown, setCurrentMarkdown] = useState(newMarkdown);

  const isMarkdownChanged = newMarkdown !== currentMarkdown;

  useEffect(() => {
    async function saveGuideline(
      guidelineFileHandle: FileSystemFileHandle
    ): Promise<void> {
      if (newMarkdown.trim().length > 0) {
        setIsSaving(true);
        try {
          const writableStream = await guidelineFileHandle.createWritable();
          try {
            await writableStream.write(newMarkdown);
            setCurrentMarkdown(newMarkdown);
          } catch (error: any) {
            setSaveError(
              new Error('File format not supported - changes will not be saved')
            );
          } finally {
            await writableStream.close();
          }
        } catch (error: any) {
          setSaveError(error);
        } finally {
          setIsSaving(false);
        }
      }
    }

    if (canHandleGuideline.current && fileHandle && isMarkdownChanged) {
      saveGuideline(fileHandle);
    }
  }, [fileHandle, isMarkdownChanged, newMarkdown, setIsSaving, setSaveError]);

  return <div />;
}

function safePagesToMarkdown(guidelines: GuidelinePages): string {
  try {
    return pagesToMarkdown(guidelines);
  } catch {
    return '';
  }
}
