import { Divider, List, Box } from '@mui/material';
import { useCallback } from 'react';
import { MenuItem } from '../../model/GuidelinePage';
import { SubMenuDisplayAndEditor } from './SubMenuDisplayAndEditor';

export type SubMenusDisplayAndEditorProps = {
  menuItems: MenuItem[];
  setMenuItems: (menuItems: MenuItem[]) => void;
};
export function SubMenusDisplayAndEditor({
  menuItems,
  setMenuItems,
}: SubMenusDisplayAndEditorProps): JSX.Element {
  const handleTitleAndSubtitleChange = useCallback(
    (index: number, newTitle: string, newSubtitle: string) => {
      const updatedMenuItems = { ...menuItems };
      updatedMenuItems[index].title = newTitle;
      updatedMenuItems[index].subtitle = newSubtitle;
      setMenuItems(updatedMenuItems);
    },
    [menuItems, setMenuItems]
  );

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Divider />
      <List>
        {menuItems.map((menuItem, index) => (
          <SubMenuDisplayAndEditor
            title={menuItem.title}
            subtitle={menuItem.subtitle}
            targetPageId={menuItem.target}
            index={index}
            key={menuItem.target}
            setTitleAndSubtitle={handleTitleAndSubtitleChange}
          />
        ))}
      </List>
    </Box>
  );
}
