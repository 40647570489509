import { useState, useCallback } from 'react';
import { SubPageListItemLink } from './SubPageListItemLink';
import { TitleEditorDialog } from './TitleEditorDialog';

export type SubMenuProps = {
  title: string;
  subtitle?: string;
  targetPageId: string;
  index: number;
  setTitleAndSubtitle: (
    index: number,
    newTitle: string,
    newSubtitle: string
  ) => void;
};

export function SubMenuDisplayAndEditor({
  title,
  subtitle,
  targetPageId,
  index,
  setTitleAndSubtitle,
}: SubMenuProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTitleEditorDialogOpen, setIsTitleEditorDialogOpen] = useState(false);

  const handleClickOnText = useCallback(() => {
    setIsTitleEditorDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => setIsTitleEditorDialogOpen(false), []);

  const updateTitleAndSubtitle = useCallback(
    (newTitle: string, newSubtitle: string) =>
      setTitleAndSubtitle(index, newTitle, newSubtitle),
    [index, setTitleAndSubtitle]
  );

  return (
    <>
      <TitleEditorDialog
        open={isTitleEditorDialogOpen}
        title={title}
        subtitle={subtitle ?? ''}
        handleClose={handleClose}
        updateTitleAndSubtitle={updateTitleAndSubtitle}
      />
      <SubPageListItemLink
        primary={title}
        secondary={subtitle}
        pageId={targetPageId}
        handleClickOnText={handleClickOnText}
      />
    </>

    // <ListItem
    //   divider
    //   disablePadding
    //   secondaryAction={
    //     <Button endIcon={<OpenInNewIcon />} onClick={handleClickOnOpen}>
    //       Open
    //     </Button>
    //   }
    // >
    //   <ListItemButton
    //     onClick={handleClickOnText}
    //     // disableGutters
    //     sx={{
    //       paddingY: 0,
    //     }}
    //   >
    //     <ListItemText
    //       sx={{
    //         marginTop: 0,
    //         // backgroundColor: 'lightblue',
    //       }}
    //       primary={title}
    //       secondary={subtitle}
    //     />
    //   </ListItemButton>
    // </ListItem>
  );
}
