import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Readable } from 'node:stream';
import { GuidelinePage } from '../model/GuidelinePage';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';
import { buildGuidelinesMap } from '../guideline_parser/GuidelineParser';
import { GuidelinesAndSource } from './GuidelinesProvider';

// Set the AWS Region.
const REGION = 'eu-west-1';
// Create an Amazon S3 service client object.
const s3Client = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: 'eu-west-1:7fbc0643-369d-4cb1-9d51-7ddcb95ff4d5',
  }),
});

const moduleRegEx = /public\/(.*)\/(.*)\.json/;
const keyRegEx = /public\/(.*)\.json/;

export async function loadGuidelinesIndexFromS3(
  srcBucket: string
): Promise<GuidelinesIndexEntry[]> {
  const srcKey = `index.json`;

  const indexEntries = (await loadJsonFileFromS3(
    srcBucket,
    srcKey
  )) as GuidelinesIndexEntry[];

  const cleanAndPopulatedIndexEntries =
    populateModuleAndGuidelineName(indexEntries);

  return cleanAndPopulatedIndexEntries;
}

function populateModuleAndGuidelineName(
  rawIndexEntries: GuidelinesIndexEntry[]
): GuidelinesIndexEntry[] {
  const populatedIndexEntries = rawIndexEntries.map((indexEntry) => {
    const { moduleName, guidelineName, guidelinePath } = decodeKey(
      indexEntry.key
    );
    if (moduleName !== '') {
      return {
        key: indexEntry.key,
        md5: indexEntry.md5,
        module: moduleName,
        guidelineName,
        path: guidelinePath,
      } as GuidelinesIndexEntry;
    }
    return undefined;
  });

  const cleanAndPopulatedIndexEntries = populatedIndexEntries.filter(
    (item) => item
  ) as GuidelinesIndexEntry[];

  return cleanAndPopulatedIndexEntries;
}

function decodeKey(key: string): {
  moduleName: string;
  guidelineName: string;
  guidelinePath: string;
} {
  const [, moduleName, guidelineName] = key.match(moduleRegEx) ?? ['', '', ''];
  const [, guidelinePath] = key.match(keyRegEx) ?? ['', ''];
  return { moduleName, guidelineName, guidelinePath };
}

export async function loadGuidelineFromS3(
  srcBucket: string,
  rootPath: string,
  guidelineKey: string
): Promise<GuidelinesAndSource> {
  const srcKey = `${rootPath}/${guidelineKey}.json`;
  let pagesToReturn: GuidelinePage[] = [];
  try {
    const jsonGuidelines = await loadJsonFileFromS3(srcBucket, srcKey);
    pagesToReturn = jsonGuidelines as GuidelinePage[];
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    const errorPage = {
      id: '',
      title: `Error loading guideline`,
      sections: [
        {
          content: `${errorMessage}\n\nReceived reading '${srcKey}'`,
          items: [],
        },
      ],
    };

    pagesToReturn = [errorPage];
  }

  const pagesById = buildGuidelinesMap(pagesToReturn);
  const guideline = { rootPage: pagesToReturn[0], allPagesById: pagesById };

  return { guideline };
}

async function loadJsonFileFromS3(bucket: string, key: string): Promise<[]> {
  const s3Response = await s3Client.send(
    new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ResponseCacheControl: 'no-cache', // Still caches, but always checks it has the latest
    })
  );
  if (!s3Response.Body) {
    const errorMessage = `${key} returned undefined`;
    throw new Error(errorMessage);
  }

  const fileContents = await s3ResponseBodyToString(s3Response.Body);
  const contentsAsJson = JSON.parse(fileContents);
  return contentsAsJson;
}

function isReadableStream(
  body: ReadableStream | Readable | Blob
): body is ReadableStream {
  return (body as ReadableStream).getReader !== undefined;
}

function isReadable(body: ReadableStream | Readable | Blob): body is Readable {
  return (body as Readable).read !== undefined;
}

async function s3ResponseBodyToString(
  body: ReadableStream | Readable | Blob
): Promise<string> {
  if (isReadableStream(body)) return readableStreamToString(body);
  if (isReadable(body)) return readableToString(body);
  return body.text();
}

async function readableStreamToString(stream: ReadableStream): Promise<string> {
  const chunks: Uint8Array[] = [];
  const reader = stream.getReader();

  if (!stream.getReader) {
    throw new Error('Not a ReadableStream');
  }

  let isMoreData = true;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();
    if (done) {
      isMoreData = false;
    } else {
      chunks.push(value as Uint8Array);
    }
  } while (isMoreData);

  return DecodeUint8Array(concat(chunks));
}

async function readableToString(reader: Readable): Promise<string> {
  const chunks: Buffer[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const chunk of reader) {
    chunks.push(chunk as Buffer);
  }

  const allData = Buffer.concat(chunks);
  return allData.toString('utf-8');
}

function concat(arrays: Uint8Array[]): Uint8Array {
  // sum of individual array lengths
  const totalLength = arrays.reduce((acc, value) => acc + value.length, 0);

  if (!arrays.length) return new Uint8Array();

  const result = new Uint8Array(totalLength);

  // for each array - copy it over result
  // next array is copied right after the previous one
  let length = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const array of arrays) {
    result.set(array, length);
    length += array.length;
  }

  return result;
}

/**
 * Convert an Uint8Array into a string.
 *
 * @returns {String}
 */
function DecodeUint8Array(array: Uint8Array): string {
  return new TextDecoder('utf-8').decode(array);
}
