import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export type AuthoringAppBarProps = {
  title: string;
  drawerWidthInPx: number;
  onRefresh: () => void;
};

export function AuthoringAppBar({
  title,
  drawerWidthInPx,
  onRefresh,
}: AuthoringAppBarProps): JSX.Element {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidthInPx}px)`,
        ml: `${drawerWidthInPx}px`,
      }}
    >
      <Toolbar>
        <IconButton onClick={onRefresh} color="inherit" sx={{ marginRight: 2 }}>
          <RefreshIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
