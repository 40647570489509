import { Stack, Typography } from '@mui/material';
import './SectionContent.css';

interface SectionHeadingProps {
  heading?: string;
}

export function SectionHeading({
  heading = '',
}: SectionHeadingProps): JSX.Element {
  const noHeading = heading.trim().length === 0;
  if (noHeading) return <div />;

  return (
    <Stack direction="column" width="100%">
      <Typography
        variant="h5"
        component="h2"
        style={{
          textAlign: 'left',
          marginLeft: '-1rem',
          marginRight: '-1rem',
          paddingLeft: '1rem',
        }}
      >
        {heading}
      </Typography>
    </Stack>
  );
}

SectionHeading.defaultProps = { heading: '' };
