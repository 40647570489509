import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Grid,
  Chip,
  Skeleton,
  Typography,
  DialogTitle,
  Input,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, ChangeEvent, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';
import { guidelinePathToUrlParam } from '../services/GuidelinePathToUrlParam';
import {
  doesTitleIncludeFph,
  doesTitleIncludeWph,
  removeSiteSpecifierFromName,
} from './ThemeAndTitleProvider';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import { categorizeGuidelines } from '../services/GuidelinesIndexUtils';

// For top aligning the search dialog, rather than the default of centred
const positionTopStyle = makeStyles(() => ({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  paper: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
  },
}));

interface SearchGuidelinesDialogProps {
  open: boolean;
  handleClose: () => void;
}

export function SearchGuidelinesDialog({
  open,
  handleClose,
}: SearchGuidelinesDialogProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  const [
    guidelineEntriesByCategoryAndFolder,
    setGuidelineEntriesByCategoryAndFolder,
  ] = useState(new Map<string, GuidelinesIndexEntry[]>());

  useEffect(() => {
    async function getGuidelinesIndex(): Promise<void> {
      setLoading(true);

      try {
        if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
        const guidelinesIndex = await loadGuidelinesIndex();
        const tempGuidelineEntriesByCategoryAndFolder = categorizeGuidelines(
          guidelinesIndex,
          'CategoryAndSubfolder'
        );
        setGuidelineEntriesByCategoryAndFolder(
          tempGuidelineEntriesByCategoryAndFolder
        );
      } catch (innerError) {
        const typedError = innerError as { message: string };
        setError(typedError.message);
      } finally {
        setLoading(false);
      }
    }

    getGuidelinesIndex();
  }, [loadGuidelinesIndex]);

  const [searchText, setSearchText] = useState('');
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newSearchText = event.target.value;
      setSearchText(newSearchText);
    },
    []
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let searchResultsElement = <div />;

  if (loading) {
    searchResultsElement = <Skeleton variant="text" />;
  }

  if (error !== '') {
    searchResultsElement = <li>{error}</li>;
  }

  if (!loading && !error) {
    const searchResults = filterGuidelinesIndex(
      guidelineEntriesByCategoryAndFolder,
      searchText.trim()
    );
    const searchResultElements = buildSearchResultElements(
      searchResults,
      searchText.trim(),
      handleClose
    );
    const hasResults = searchResultElements.length > 0;

    searchResultsElement = hasResults ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{searchResultElements}</>
    ) : (
      <Typography>{`No matches for '${searchText}'`}</Typography>
    );
  }

  // For aligning the dialog to the top of the screen
  const classes = positionTopStyle();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
        paper: classes.paper,
      }}
    >
      <DialogTitle bgcolor="inherit">
        <Input
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchChange}
          value={searchText}
          autoFocus
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="reset" onClick={handleClose} color="primary">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent>{searchResultsElement}</DialogContent>
    </Dialog>
  );
}

function guidelineNameContainsSearchText(
  indexEntry: GuidelinesIndexEntry,
  searchText: string
) {
  return indexEntry.guidelineName
    .toLocaleLowerCase()
    .includes(searchText.toLocaleLowerCase());
}

function filterGuidelinesIndex(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  searchText: string
) {
  const filteredEntries = new Map<string, GuidelinesIndexEntry[]>();

  guidelineEntriesByCategory.forEach(
    (entriesInCategoryAndSubfolder, categoryAndSubfolderName) => {
      const indexEntriesContainingSearchText =
        entriesInCategoryAndSubfolder.filter((indexEntry) =>
          guidelineNameContainsSearchText(indexEntry, searchText)
        );

      const hasResultsInThisFolder =
        indexEntriesContainingSearchText.length > 0;

      if (hasResultsInThisFolder) {
        filteredEntries.set(
          categoryAndSubfolderName,
          indexEntriesContainingSearchText
        );
      }
    }
  );

  return filteredEntries;
}

function buildSearchResultElements(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  handleClose: () => void
) {
  const categoryElements: JSX.Element[] = [];

  guidelineEntriesByCategory.forEach(
    (indexEntriesContainingSearchText, categoryAndSubfolderName) => {
      const key = `${categoryAndSubfolderName}-${searchText}`;
      const folderElements = buildFolderResultElements(
        indexEntriesContainingSearchText,
        key,
        categoryAndSubfolderName,
        handleClose
      );

      categoryElements.push(...folderElements);
    }
  );

  return categoryElements;
}

// Would be good to read this rather than hard code it
const dialogContentPadding = '24px';

function buildFolderResultElements(
  entriesInCategoryAndSubfolder: GuidelinesIndexEntry[],
  key: string,
  categoryAndSubfolderName: string,
  handleClose: () => void
) {
  const headerElement = (
    <Grid
      item
      xs={12}
      key={key}
      bgcolor="lightgrey"
      style={{
        // Go full width by setting our margin to the opposite
        // of the parent's padding
        marginLeft: `-${dialogContentPadding}`,
        marginRight: `-${dialogContentPadding}`,
        paddingLeft: '1rem',
      }}
    >
      <Typography variant="body2">{categoryAndSubfolderName}</Typography>
    </Grid>
  );

  const buttonElements = entriesInCategoryAndSubfolder.map((guidelineEntry) =>
    buildGuidelineButton(guidelineEntry, handleClose)
  );

  const folderElements = [headerElement, ...buttonElements];
  return folderElements;
}

function buildGuidelineButton(
  guidelineEntry: GuidelinesIndexEntry,
  handleClose: () => void
) {
  const safeUrl = guidelinePathToUrlParam(guidelineEntry.path);

  // TODO: Abstract this out of the core code
  const [nameToDisplay, badgeElement] = getSiteSpecificNameAndBadge(
    guidelineEntry.guidelineName
  );

  return (
    <Grid item xs={12} sm={6} key={guidelineEntry.key}>
      <Button
        component={RouterLink}
        to={safeUrl}
        style={{ textTransform: 'none', justifyContent: 'flex-start' }}
        onClick={handleClose}
      >
        {badgeElement}
        {nameToDisplay}
      </Button>
    </Grid>
  );
}

function getSiteSpecificNameAndBadge(
  guidelineName: string
): [string, JSX.Element] {
  const titleIncludesFph = doesTitleIncludeFph(guidelineName);
  const titleIncludesWph = doesTitleIncludeWph(guidelineName);

  let badgeElement = <div />;

  if (titleIncludesFph && !titleIncludesWph) {
    badgeElement = (
      <Chip
        size="small"
        color="frimley"
        label="FP"
        key="FP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  if (titleIncludesWph && !titleIncludesFph) {
    badgeElement = (
      <Chip
        size="small"
        color="wexham"
        label="WP"
        key="WP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  const nameToDisplay = removeSiteSpecifierFromName(guidelineName);

  return [nameToDisplay, badgeElement];
}
