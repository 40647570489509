import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export type TemplateGuidelineCardProps = {
  title: string;
  description: string;
  onClick: (key: string) => void;
};

export function TemplateGuidelineCard({
  title,
  description,
  onClick,
}: TemplateGuidelineCardProps): JSX.Element {
  const onClickCallback = (): void => onClick(title);

  return (
    <Card
        sx={{
          width: 300,
          ':hover': {
            boxShadow: 10, // theme.shadows[20]
          },
        }}
      >
        <CardActionArea onClick={onClickCallback}>
          <CardContent>
            <Stack direction="row" justifyContent="flex-end">
              <AddCircleOutlineIcon />
            </Stack>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
  );
}
