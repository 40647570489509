import { markdownToPages } from '../guideline_parser/GuidelineParser';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndexEntry';
import { GuidelinesAndSource } from './GuidelinesProvider';

export async function loadGuidelinesIndexFromFileSystem(
  rootPath: FileSystemDirectoryHandle
): Promise<GuidelinesIndexEntry[]> {
  return findGuidelines(rootPath);
}

async function findNestedGuidelines(
  directoryHandle: FileSystemDirectoryHandle,
  parentPath: string
): Promise<GuidelinesIndexEntry[]> {
  const allGuidelines: GuidelinesIndexEntry[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of directoryHandle.values()) {
    const isHiddenFile =
      entry.name.startsWith('.') ||
      entry.name === 'README.md' ||
      entry.name.endsWith('-media');
    const isDirectory = entry.kind === 'directory';
    const isMarkdownFile = entry.name.endsWith('.md');
    const isPdfFile = entry.name.endsWith('.pdf');

    if (!isHiddenFile) {
      if (isDirectory) {
        const newParentPath =
          parentPath.length > 0
            ? `${parentPath}/${entry.name}`
            : `${entry.name}`;
        allGuidelines.push(
          ...(await findNestedGuidelines(entry, newParentPath))
        );
      } else if (isMarkdownFile || isPdfFile) {
        const guidelineName = entry.name.replace('.md', '').replace('.pdf', '');
        const module = `${parentPath}`;
        const path = `${module}/${guidelineName}`;
        const guidelineIndexEntry: GuidelinesIndexEntry = {
          key: path,
          md5: '',
          module,
          guidelineName,
          path,
          extension: getFileExtension(entry.name),
          fileHandle: entry,
          parentFolderHandle: directoryHandle,
        };
        allGuidelines.push(guidelineIndexEntry);
      }
    }
  }

  return allGuidelines;
}

async function findGuidelines(
  directoryHandle: FileSystemDirectoryHandle
): Promise<GuidelinesIndexEntry[]> {
  const allGuidelines: GuidelinesIndexEntry[] = await findNestedGuidelines(
    directoryHandle,
    ''
  );

  const noDuplicatePdfGuidelines = filterOutPdfDuplicates(allGuidelines);

  const sortedGuidelines = noDuplicatePdfGuidelines.sort((a, b) => {
    const moduleComparison = a.module.localeCompare(b.module);
    if (moduleComparison !== 0) return moduleComparison;
    return a.guidelineName.localeCompare(b.guidelineName);
  });
  return sortedGuidelines;
}

function filterOutPdfDuplicates(allGuidelines: GuidelinesIndexEntry[]) {
  const markdownGuidelines = allGuidelines.filter(
    (entry) => entry.extension === 'md'
  );
  const pdfGuidelines = allGuidelines.filter(
    (entry) => entry.extension === 'pdf'
  );
  const noDuplicatePdfGuidelines = pdfGuidelines.filter((entry) => {
    const { module, guidelineName } = entry;
    const matchingMarkdownGuideline = markdownGuidelines.find(
      (markdownEntry) =>
        markdownEntry.module === module &&
        markdownEntry.guidelineName === guidelineName
    );
    return matchingMarkdownGuideline === undefined;
  });
  // return [...noDuplicatePdfGuidelines]; // For finding lone PDFs
  return [...markdownGuidelines, ...noDuplicatePdfGuidelines];
}

function getFileExtension(filename: string) {
  return filename.substring(filename.lastIndexOf('.') + 1) || filename;
}

export interface ResourcePathAndUrl {
  path: string;
  url: string;
}

async function findResources(
  directoryHandle: FileSystemDirectoryHandle,
  rootDirectoryHandle = directoryHandle
): Promise<ResourcePathAndUrl[]> {
  const allResources: ResourcePathAndUrl[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of directoryHandle.values()) {
    const isHiddenFile = entry.name.startsWith('.');
    const isDirectory = entry.kind === 'directory';
    const isMarkdownFile = entry.name.endsWith('.md');

    if (!isHiddenFile) {
      if (isDirectory) {
        allResources.push(...(await findResources(entry, rootDirectoryHandle)));
      } else if (!isMarkdownFile) {
        const allFolders = await rootDirectoryHandle.resolve(entry);
        if (!allFolders) return allResources;
        const path = allFolders.join('/');
        const url = URL.createObjectURL(await entry.getFile());
        allResources.push({ path, url });
      }
    }
  }
  return allResources;
}

export async function findResourceUrlsBySourcePath(
  rootPath: FileSystemDirectoryHandle
): Promise<Map<string, ResourcePathAndUrl[]>> {
  const sourcePathToResourceUrlMap = new Map<string, ResourcePathAndUrl[]>();

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of rootPath.values()) {
    const isHiddenFile = entry.name.startsWith('.');
    const isDirectory = entry.kind === 'directory';

    if (!isHiddenFile && isDirectory) {
      // recurse through this directory, building a path for every resource and saving it and the file handle
      const sourcePath = entry.name;
      const allResources = await findResources(entry);
      sourcePathToResourceUrlMap.set(sourcePath, allResources);
    }
  }
  return sourcePathToResourceUrlMap;
}

export async function loadGuidelineMarkdownFromFileSystem(
  fileHandle: FileSystemFileHandle
): Promise<string> {
  const guidelineFile = await fileHandle.getFile();
  const guidelineText = await guidelineFile.text();
  return guidelineText;
}

export async function loadGuidelineFromFileSystem(
  guidelineIndexToOpen: GuidelinesIndexEntry
): Promise<GuidelinesAndSource> {
  const fileHandle = guidelineIndexToOpen.fileHandle!;

  const extension = getFileExtension(fileHandle.name).toLowerCase();
  if (extension === 'pdf') {
    return buildPdfWrapper(fileHandle.name, guidelineIndexToOpen.module);
  }

  const guidelineText = await loadGuidelineMarkdownFromFileSystem(fileHandle);
  const pages = markdownToPages(guidelineText);
  return { guideline: pages, fileHandle };
}

function buildPdfWrapper(
  filename: string,
  module: string
): GuidelinesAndSource {
  const baseFilename = filename.substring(0, filename.lastIndexOf('.'));
  const isInSubFolder = module.includes('/');
  const path = isInSubFolder
    ? `${module.substring(module.indexOf('/') + 1)}/`
    : '';
  const pdfWrapperMarkdown = `# ${baseFilename}\n>> View PDF [target:${path}${filename}]
<<`;

  const pdfWrapperGuideline = markdownToPages(pdfWrapperMarkdown);
  return { guideline: pdfWrapperGuideline };
}
